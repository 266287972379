<!-- VUETIFY2 - OK -->
<template>
    <div id="dashboard">
        <loading-screen ref="loadingComponent"/>
        <br/>
        <v-row no-gutters>
            <v-col class="ml-5" md="5" lg="5" xl="5">
                <span>Veuillez sélectionner une date afin de visualiser l'état de votre portefeuille à cette date :</span>
            </v-col>
        </v-row>
        <br/>
        <v-row no-gutters>
            <v-col class="ml-5" md="2" lg="2" xl="2">
                <DateField ref="date_annu"
                           v-model="dateDataCharts"
                           label="Date de référence"
                           :clearable="false"/>
            </v-col>
        </v-row>
        <br/>
        <v-row no-gutters justify="space-around">
            <v-col md="4" lg="4" xl="4">
                <conteneur-graphique type="pieorbar" :title="portefeuilleAssocieDataTitle"
                                     :data="portefeuilleAssocieData"
                                     :date="dateDataCharts"></conteneur-graphique>
            </v-col>
            <v-col md="4" lg="4" xl="4">
                <conteneur-graphique type="line_cap" :title="evolutionPortefeuilleAssocieDataTitle"
                                     :data="evolutionPortefeuilleAssocieData"
                                     :date="dateDataCharts"></conteneur-graphique>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import moment from "moment/moment";
    import DateField from "@/components/DateField.vue";
    import internalApi from "@/api/internalApi";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import ConteneurGraphique from "@/components/ConteneurGraphique.vue";

    /**
     * Composant affichant, à une date donnée, des graphiques indiquant l'état
     * du portefeuille de l'associé
     */
    export default {
        components: {
            DateField,
            loadingScreen,
            ConteneurGraphique,
        },
        data() {
            return {
                dateDataCharts: moment().format("YYYY-MM-DD"),
                portefeuilleAssocieDataTitle: "Répartition du portefeuille",
                portefeuilleAssocieData: undefined,
                evolutionPortefeuilleAssocieDataTitle: "Evolution du portefeuille",
                evolutionPortefeuilleAssocieData: undefined,
            };
        },
        watch: {
            dateDataCharts: function () {
                this.callAssocieCharts();
            }
        },
        mounted() {
            this.callAssocieCharts();
        },
        methods: {
            /**
             * Récupère les graphiques de l'associé à une date donnée
             */
            callAssocieCharts() {
                if (!_.isNil(this.dateDataCharts)) {
                    this.$refs.loadingComponent.setLoading("Chargement des graphiques");
                    internalApi.capCharts.associeData(this.dateDataCharts)
                        .then(result => {
                            this.$refs.loadingComponent.resetLoading();
                            this.portefeuilleAssocieData = result.portefeuille_associe;
                            this.evolutionPortefeuilleAssocieData = result.evolution_portefeuille_associe;
                        })
                        .catch(err => {
                            console.error("Erreur lors de la récupération des graphiques : ", err);
                            this.$refs.loadingComponent.resetLoading();
                        });
                }
            },
        }
    };
</script>
