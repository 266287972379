<!-- VUETIFY2 - OK -->
<template>
    <base-layout :withFooter="false" datatableExcludedSize="140px">
        <template v-slot:header>
            <h1>Déblocages</h1>
        </template>
        <template v-slot:main="{ datatableSize }">
            <v-datatable-deblocages-espace-beneficiaire ref="deblocages"
                                                        :showBeneficiaires="false"
                                                        :height="datatableSize"/>
        </template>
    </base-layout>
</template>

<script>
    import VDatatableDeblocagesEspaceBeneficiaire from "@/components/deblocages/VDatatableDeblocagesEspaceBeneficiaire";
    import BaseLayout from "@/components/BaseLayout";

    export default {
        components: {
            BaseLayout,
            VDatatableDeblocagesEspaceBeneficiaire,
        },
        name: "DeblocagesEspaceBeneficiaire",
    }
</script>