<!-- VUETIFY2 - OK -->
<template>
    <base-layout id="dashboard" :withFooter="false" headerSize="130px">
        <template v-slot:header>
            <v-tabs v-model="currentTab">
                <v-tab key="company" @click="onCompanyClick">Societé</v-tab>
                <v-tab key="associes" @click="onAssocieClick">Associé(e)</v-tab>
                <v-tab key="droitsdevote" @click="onDroitsDeVoteClick">Droits de vote</v-tab>
                <v-tab key="rapports" @click="onRapportClick">Rapports</v-tab>
                <v-tab key="scic" :disabled="! hasScicExtensionAccess()" @click="onScicClick">SCIC</v-tab>
            </v-tabs>
            <v-row class="mt-1">
                <v-col md="3" lg="3" xl="2">
                    <DateField ref="date_annu"
                               v-model="date_reference"
                               label="Date de référence"
                               :clearable="false"/>
                </v-col>
                <v-col md="4" lg="4" xl="4" class="ml-5" v-if="showAssocies">
                    <ValidationProvider vid="associe_id"
                                        name="Associé(e)"
                                        v-slot="{ errors }">
                        <v-associes-list v-if="showAssocies"
                                         :value="associe_id"
                                         label="Associé(e)"
                                         @input="associe_id = $event;  callAssocieCharts()"
                                         :errors="errors"
                        />
                    </ValidationProvider>
                </v-col>
            </v-row>
        </template>
        <template v-slot:main>
            <loading-screen ref="loadingComponent"/>
            <v-tabs-items v-model="currentTab">

                <v-tab-item key="company">
                    <base-layout :withHeader="false" :withFooter="false">
                        <template v-slot:main>
                            <v-container>
                                <v-row justify="space-around">
                                    <v-col cols="6">
                                        <conteneur-graphique type="pieorbar" :title="syntheseCapitalTypeAssocieDataTitle"
                                                             :data="syntheseCapitalTypeAssocieData"
                                                             :errorMessage="errorMessage"
                                                             :date="date_reference"/>
                                            
                                        
                                    </v-col>
                                    <v-col cols="6">
                                        <conteneur-graphique type="pieorbar" 
                                                             :title="syntheseCapitalParCategorieDataTitle"
                                                             :data="syntheseCapitalParCategorieData"
                                                             :errorMessage="errorMessage"
                                                             :date="date_reference"/>
                                        
                                    </v-col>
                                </v-row>
                                <v-row justify="space-around">
                                    <v-col cols="6">
                                        <conteneur-graphique type="pieorbar" 
                                                             :title="syntheseCapitalParAssocieDataTitle"
                                                             :data="syntheseCapitalParAssocieData"
                                                             :errorMessage="errorMessage"
                                                             :date="date_reference"/>
                                        
                                    </v-col>
                                    <v-col cols="6">
                                        <conteneur-graphique type="line_cap" 
                                                             :title="evolutionCapitalDataTitle"
                                                             :data="evolutionCapitalData"
                                                             :errorMessage="errorMessage"
                                                             :date="date_reference"/>
                                        
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                    </base-layout>
                </v-tab-item>

                <v-tab-item key="associes">
                    <base-layout :withHeader="false" :withFooter="false">
                        <template v-slot:main>
                            <v-container>
                                <template v-if="associe_id">
                                    <v-row justify="space-around">
                                        <v-col cols="6">
                                            <conteneur-graphique type="pieorbar" :title="portefeuilleAssocieDataTitle"
                                                                 :data="portefeuilleAssocieData"
                                                                 :errorMessage="errorMessage"
                                                                 :date="date_reference"/>
                                        </v-col>
                                        <v-col cols="6">
                                            <conteneur-graphique type="line_cap" :title="evolutionPortefeuilleAssocieDataTitle"
                                                                 :data="evolutionPortefeuilleAssocieData"
                                                                 :errorMessage="errorMessage"
                                                                 :date="date_reference"/>
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-else>
                                    Veuillez sélectionner un(e) associé(e) via le champ en haut de page.
                                </template>
                            </v-container>
                        </template>
                    </base-layout>
                </v-tab-item>

                <v-tab-item key="droitsdevote">
                    <base-layout :withHeader="false" :withFooter="false">
                        <template v-slot:main>
                            <v-container>
                                <v-row no-gutters v-if="droitsDeVoteValide === false" class="mb-5" align="center" justify="center">
                                    <v-col class="text-center">
                                            <v-icon color="error">{{ "$vuetify.icons.error" }}</v-icon>
                                            La répartition des droits de vote n'est pas significative en raison du dépassement des seuils légaux.
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <!-- Petits boutons -->
                                    <v-col cols="6">
                                        <v-data-table-download-reports :rapports="droits_vote_items"/>
                                    </v-col>
                                    <v-col cols="6">
                                        <conteneur-graphique type="pieorbar" :title="droitsDeVoteDataTitle"
                                                             :data="droitsDeVoteData"
                                                             :date="date_reference"/>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                    </base-layout>
                </v-tab-item>

                <v-tab-item key="rapports">
                    <base-layout :withHeader="false" :withFooter="false">
                        <template v-slot:main>
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <h2>Capital de la société</h2>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="12" lg="8" xl="6">
                                        <v-data-table-download-reports :rapports="rapports_items"/>

                                        <!-- Popups pouvant être appelées par les boutons du v-data-table-download-reports ci-dessus -->
                                        <v-popup-asynchrone-etats-du-capital :dateReference="date_reference" :showButton="false" ref="popup_asynchrone_etats_du_capital"/>
                                        <!-- --------------------------------------------------------------------------------------- -->

                                    </v-col>
                                </v-row>
                                <template v-if="hasScicExtensionAccess()">
                                    <v-row>
                                        <v-col>
                                            <h2>SCIC</h2>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col md="12" lg="8" xl="6">
                                            <v-data-table-download-reports :rapports="rapports_items_scic"/>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-container>
                        </template>
                    </base-layout>
                </v-tab-item>

                <v-tab-item key="scic">
                    <base-layout :withHeader="false" :withFooter="false">
                        <template v-slot:main>
                            <v-container>
                                <v-row justify="space-around">
                                    <v-col cols="6">
                                        <conteneur-graphique type="pieorbar" :title="paramCategorieGraphRepartitionMontantCapitalTitle"
                                                             :data="paramCategorieGraphRepartitionMontantCapitalData"
                                                             :date="date_reference"/>
                                    </v-col>
                                    <v-col cols="6">
                                        <conteneur-graphique type="pieorbar" :title="sousCategorieGraphRepartitionMontantCapitalTitle"
                                                             :data="sousCategorieGraphRepartitionMontantCapitalData"
                                                             :date="date_reference"/>
                                    </v-col>
                                </v-row>
                                <v-row justify="space-around">
                                    <v-col cols="6">
                                        <conteneur-graphique type="pieorbar" :title="collegeGraphRepartitionAssociesTitle"
                                                             :data="collegeGraphRepartitionAssociesData"
                                                             :date="date_reference"/>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                    </base-layout>
                </v-tab-item>

            </v-tabs-items>
        </template>
    </base-layout>
</template>

<script>
    import moment from "moment/moment";
    import DateField from "@/components/DateField.vue";
    import internalApi from "@/api/internalApi";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import Utils from "@/utils.js";
    import constantes from "@/utils/constantes";
    import ConteneurGraphique from "@/components/ConteneurGraphique.vue";
    import VAssociesList from "@/components/VAssociesList.vue";
    import BaseLayout from "@/components/BaseLayout";
    import authService from "../../services/authService";
    import VDataTableDownloadReports from "@/components/VDataTableDownloadReports.vue";
    import VPopupAsynchroneEtatsDuCapital from "@/components/dashboardCapital/VPopupAsynchroneEtatsDuCapital.vue";

    export default {
        components: {
            DateField,
            loadingScreen,
            ConteneurGraphique,
            VAssociesList,
            BaseLayout,
            VDataTableDownloadReports,
            VPopupAsynchroneEtatsDuCapital,
        },
        data() {
            return {
                showAssocies: false,
                currentTab: undefined,
                date_reference: moment().format("YYYY-MM-DD"),
                associe_id: undefined,
                portefeuilleAssocieDataTitle: "Répartition du portefeuille",
                portefeuilleAssocieData: undefined,
                evolutionPortefeuilleAssocieDataTitle: "Évolution du portefeuille",
                evolutionPortefeuilleAssocieData: undefined,
                syntheseCapitalTypeAssocieDataTitle: "Répartition du capital par type d'associé(e)",
                syntheseCapitalTypeAssocieData: undefined,
                syntheseCapitalParCategorieDataTitle: "Répartition du capital par catégorie",
                syntheseCapitalParCategorieData: undefined,
                syntheseCapitalParAssocieDataTitle: "Répartition du capital par associé(e)",
                syntheseCapitalParAssocieData: undefined,
                evolutionCapitalDataTitle: "Evolution du capital",
                evolutionCapitalData: undefined,
                droitsDeVoteDataTitle: "Répartition des droits de vote de la société",
                droitsDeVoteData: undefined,
                paramCategorieGraphRepartitionMontantCapitalTitle: "Répartition du capital par catégories d'associé(e)s",
                sousCategorieGraphRepartitionMontantCapitalTitle: "Répartition du capital par sous-catégories d'associé(e)s",
                collegeGraphRepartitionAssociesTitle: "Répartition des associé(e)s par collèges de vote",
                paramCategorieGraphRepartitionMontantCapitalData: undefined,
                sousCategorieGraphRepartitionMontantCapitalData: undefined,
                collegeGraphRepartitionAssociesData: undefined,
                rapports_headers: [
                    {text: "Nom du rapport", value: "name", align: "start"},
                    {text: "Fonction à exécuter", value: "downloadFunction"},
                ],
                rapports_items: [
                    {
                        name: "Évolution du capital",
                        downloadFunction: this.downloadExcelEvolution,
                        icon: "$vuetify.icons.excel",
                    },
                    {
                        name: "Portefeuille des associé(e)s à date",
                        downloadFunction: this.downloadExcelPortefeuille,
                        icon: "$vuetify.icons.excel",
                    },
                    {
                        name: "Rapport du commissaire aux comptes",
                        downloadFunction: this.downloadRapportCac,
                        icon: "$vuetify.icons.pdf",
                    },
                    {
                        name: "Libération des souscriptions",
                        downloadFunction: this.downloadLiberationSouscriptions,
                        icon: "$vuetify.icons.excel",
                    },
                    {
                        name: "États du capital",
                        downloadFunction: this.downloadEtatsDuCapital,
                        icon: "$vuetify.icons.pdf",
                    },
                    {
                        name: "Disponibilité du capital",
                        downloadFunction: this.downloadDisponibiliteDuCapital,
                        icon: "$vuetify.icons.excel",
                    },
                ],
                droits_vote_items: [
                    {
                        name: "Droits de vote des associé(e)s",
                        downloadFunction: this.downloadExcelDroitsVote,
                        icon: "$vuetify.icons.excel",
                    },
                    {
                        name: "Feuille d'émargement à date",
                        downloadFunction: this.downloadPdfFeuilleEmargement,
                        icon: "$vuetify.icons.pdf",
                    },
                ],
                rapports_items_scic: [
                    {
                        name: "Profil des associés",
                        downloadFunction: this.downloadProfilsAssociesScic,
                        icon: "$vuetify.icons.excel",
                    },
                ],
                checkDroitsVoteAssocieExterneHorsCoop: undefined,
                checkDroitsVoteAssocieExterneDontCoop: undefined,
                droitsDeVoteValide: undefined,
                errorMessage: undefined,
            };
        },
        watch: {
            date_reference: function () {
                this.errorMessage = undefined;
                this.resetCompanyData();
                this.onDateChartsChange();
            },
        },
        mounted() {
            this.callCompanyCharts();
            this.getConformiteDroitsDeVote();
        },
        methods: {
            hasScicExtensionAccess() {
                return authService.checkHasScicExtensionAccess();
            },
            computeDroitsDeVoteValide() {
                if (
                    ! _.isNil(this.checkDroitsVoteAssocieExterneHorsCoop)
                    && ! _.isNil(this.checkDroitsVoteAssocieExterneDontCoop)
                ) {
                    this.droitsDeVoteValide = this.checkDroitsVoteAssocieExterneHorsCoop
                                              && this.checkDroitsVoteAssocieExterneDontCoop;
                }
            },
            getConformiteDroitsDeVote() {
                internalApi.controleStatus.checkDroitsVoteAssocieExterneHorsCoop().then(result => {
                    this.checkDroitsVoteAssocieExterneHorsCoop = result.checkIsValid;
                    this.computeDroitsDeVoteValide();
                });
                internalApi.controleStatus.checkDroitsVoteAssocieExterneDontCoop().then(result => {
                    this.checkDroitsVoteAssocieExterneDontCoop = result.checkIsValid;
                    this.computeDroitsDeVoteValide();
                });
            },
            genericFuncCall(method, args = []) {
                this[method](...args)
            },
            onDateChartsChange() {
                if (this.currentTab === 0) {
                    this.callCompanyCharts();
                }
                else if (this.currentTab === 1) {
                    this.callAssocieCharts();
                }
                else if (this.currentTab === 2) {
                    this.callDroitsDeVoteCharts();
                }
                else if (this.currentTab === 3) {
                    this.callScicCharts();
                }
            },
            onAssocieClick() {
                this.showAssocies = true;
                this.callAssocieCharts();
                this.resetDroitVoteData();
                this.resetScicData();
            },
            onCompanyClick() {
                this.showAssocies = false;
            },
            onRapportClick() {
                this.resetAssocieData();
                this.resetDroitVoteData();
                this.resetScicData();
            },
            onScicClick() {
                this.resetAssocieData();
                this.resetDroitVoteData();
                this.callScicCharts();
            },
            onDroitsDeVoteClick() {
                this.callDroitsDeVoteCharts();
                this.resetAssocieData();
                this.resetScicData();
            },
            resetDroitVoteData(){
                this.droitsDeVoteData = undefined;
            },
            resetScicData() {
                this.paramCategorieGraphRepartitionMontantCapitalData = undefined;
                this.sousCategorieGraphRepartitionMontantCapitalData = undefined;
                this.collegeGraphRepartitionAssociesData = undefined;
            },
            resetAssocieData() {
                this.showAssocies = false;
                this.associe_id = undefined;
                this.portefeuilleAssocieData = undefined;
                this.evolutionPortefeuilleAssocieData = undefined;
            },
            resetCompanyData() {
                this.evolutionCapitalData = undefined;
                this.syntheseCapitalParAssocieData = undefined;
                this.syntheseCapitalParCategorieData = undefined;
                this.syntheseCapitalTypeAssocieData = undefined;
            },
            callCompanyCharts() {
                if (!_.isNil(this.date_reference)) {
                    internalApi.capCharts.allCompanyDataCapital(this.date_reference)
                        .then(result => {
                            this.$refs.loadingComponent.resetLoading();
                            this.syntheseCapitalTypeAssocieData = result.synthese_capital_type_associe;
                            this.syntheseCapitalParCategorieData = result.synthese_capital_par_categorie;
                            this.syntheseCapitalParAssocieData = result.synthese_capital_par_associe;
                            this.evolutionCapitalData = result.evolution_capital;
                        })
                        .catch(err => {
                            this.errorMessage = err;
                            console.error("Erreur lors de la récupération des graphiques : ", err);
                        }).finally(() => {
                            this.$refs.loadingComponent.resetLoading();
                        });
                }
            },
            callAssocieCharts() {
                if (!_.isNil(this.associe_id) && !_.isNil(this.date_reference)) {
                    this.$refs.loadingComponent.setLoading("Chargement des graphiques");
                    internalApi.capCharts.allAssocieData(this.associe_id, this.date_reference)
                        .then(result => {
                            this.$refs.loadingComponent.resetLoading();
                            this.portefeuilleAssocieData = result.portefeuille_associe;
                            this.evolutionPortefeuilleAssocieData = result.evolution_portefeuille_associe;
                        })
                        .catch(err => {
                            console.error("Erreur lors de la récupération des graphiques : ", err);
                        }).finally(() => {
                            this.$refs.loadingComponent.resetLoading();
                        });
                }
            },
            callDroitsDeVoteCharts() {
                if (!_.isNil(this.date_reference)) {
                    this.$refs.loadingComponent.setLoading("Chargement des graphiques");
                    internalApi.capCharts.allDroitsDeVoteData(this.date_reference)
                        .then(result => {
                            this.$refs.loadingComponent.resetLoading();
                            this.droitsDeVoteData = result.synthese_droits_de_vote_groupe_associes;
                        })
                        .catch(err => {
                            console.error("Erreur lors de la récupération des graphiques : ", err);
                        }).finally(() => {
                            this.$refs.loadingComponent.resetLoading();
                        });
                }
            },
            callScicCharts() {
                if (!_.isNil(this.date_reference)) {

                    this.$refs.loadingComponent.setLoading("Chargement des graphiques");

                    // Catégories
                    const promiseParamCategorie = internalApi.scicParamCategorie.getGraphParamCategorieGraphRepartitionMontantCapital(this.date_reference)
                        .then(result => {
                            this.paramCategorieGraphRepartitionMontantCapitalData = result;
                        }).catch(err => {
                            console.error(err);
                        });

                    // Sous-catégories
                    const promiseSousCategorie = internalApi.scicSousCategorie.getGraphSousCategorieGraphRepartitionMontantCapital(this.date_reference)
                        .then(result => {
                            this.sousCategorieGraphRepartitionMontantCapitalData = result;
                        }).catch(err => {
                            console.error(err);
                        });

                    // Collèges
                    const promiseCollege = internalApi.scicCollege.getGraphCollegeGraphRepartitionAssocies(this.date_reference)
                        .then(result => {
                            this.collegeGraphRepartitionAssociesData = result;
                        }).catch(err => {
                            console.error(err);
                        });

                    Promise.all([promiseParamCategorie, promiseSousCategorie, promiseCollege]).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
                }
            },
            downloadProfilsAssociesScic() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                internalApi.scic.getExcelProfilsAssocies(this.date_reference)
                    .then(response => {
                        Utils.downloadFile(response, "profil_scic_associes_" + moment().format("YYYY-MM-DD") + ".xlsx", Utils.excelMimetype);
                    }).catch(err => {
                        console.error(err);
                    }).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            downloadExcelEvolution() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                internalApi.capital.getEvolutionCapital(this.date_reference)
                    .then(response => {
                        Utils.downloadFile(response, "capital_evolution_" + moment(this.date_reference).format("YYYY-MM-DD") + ".xlsx", Utils.excelMimetype);
                    }).catch(err => {
                        console.error(err);
                    }).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            downloadExcelPortefeuille() {
                if (!_.isNil(this.date_reference)) {
                    this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                    internalApi.capital.getPortefeuilleByDate(this.date_reference)
                        .then(response => {
                            Utils.downloadFile(response, "extract_portefeuille_" + this.date_reference + ".xlsx", Utils.excelMimetype);
                        }).catch(err => {
                            console.error(err);
                        }).finally(() => {
                            this.$refs.loadingComponent.resetLoading();
                        });
                }
            },
            downloadRapportCac() {
                if (!_.isNil(this.date_reference)) {
                    this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                    internalApi.capital.getRapportCacADate(this.date_reference)
                        .then(response => {
                            Utils.downloadFile(response, "rapport_cac_" + this.date_reference + ".pdf", Utils.pdfMimetype);
                        }).catch(err => {
                            console.error(err);
                        }).finally(() => {
                            this.$refs.loadingComponent.resetLoading();
                        });
                }
            },
            downloadExcelDroitsVote() {
                if (!_.isNil(this.date_reference)) {
                    this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                    internalApi.associe.getDroitsDeVotePerAssocie(this.date_reference).then(response => {
                        Utils.downloadFile(response, "droits_vote_" + this.date_reference + ".xlsx", Utils.excelMimetype);
                    }).catch(err => {
                        console.error(err);
                    }).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
                }
            },
            downloadPdfFeuilleEmargement() {
                if (!_.isNil(this.date_reference)) {
                    this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                    internalApi.associe.getFeuilleEmargement(this.date_reference).then(response =>  {
                        Utils.downloadFile(response, "feuille_emargement_" + this.date_reference + ".pdf", Utils.pdfMimetype)
                    }).catch(err => {
                        console.log(err);
                    }).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
                }
            },
            downloadLiberationSouscriptions() {
                if (!_.isNil(this.date_reference)) {
                    this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                    internalApi.capital.getLiberationSouscriptions(this.date_reference)
                        .then(response => {
                            Utils.downloadFile(response, "liberation_sousriptions" + this.date_reference + ".xlsx", Utils.excelMimetype);
                        }).catch(err => {
                            console.error(err);
                        }).finally(() => {
                            this.$refs.loadingComponent.resetLoading();
                        });
                }
            },
            downloadEtatsDuCapital() {
                this.$refs.popup_asynchrone_etats_du_capital.openPopup();
            },
            downloadDisponibiliteDuCapital() {
                if (!_.isNil(this.date_reference)) {
                    this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                    internalApi.capital.getDisponibiliteDuCapital(this.date_reference)
                        .then(response => {
                            Utils.downloadFile(response, "disponibilite_du_capital_" + Utils.formatDateIsoToFr(this.date_reference) + ".xlsx", Utils.excelMimetype);
                        }).catch(err => {
                            console.error(err);
                        }).finally(() => {
                            this.$refs.loadingComponent.resetLoading();
                        });
                }
            },
        }
    };
</script>
