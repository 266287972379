<!-- VUETIFY2 - OK -->
<template>
    <v-container fluid>
        <loading-screen ref="loadingComponent"/>
        <v-row no-gutters>
            <h2>Participation et intérêts totaux</h2>
        </v-row>
        <v-row no-gutters>
            <v-tabs v-model="currentTab">
                <v-tab v-for="supp in this.participationInteretsTotauxBySupports" :key="supp.name">{{supp.name}}</v-tab>
                <v-tab-item v-for="supp in this.participationInteretsTotauxBySupports" :key="supp.name">
                    <v-row align="stretch" no-gutters>
                        <v-col align-self="stretch">
                            <v-data-table :search="search"
                                          :footer-props="footerProps"
                                          :headers="headers"
                                          :items="Object.values(supp.participation_interets_totaux)"
                                          :options.sync="options"
                                          fixed-header
                                          :height="height"
                                          :loading="loading"
                                          no-data-text="Aucun mouvement">
                                <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
                                    <v-row v-bind:key="'tooltip_' + h.value" align="center" no-gutters>
                                        <v-col align-self="end" :text-align="header.align">
                                            {{ header.text }}
                                        </v-col>
                                        <v-col v-if="header.tooltip != null" cols="1">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon small center v-on="on">{{ "$vuetify.icons.info" }}</v-icon>
                                                </template>
                                                <span v-html="header.tooltip"/>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:item="{item}">
                                    <tr>
                                        <td class="text-lg-left">
                                            {{item.libelle_exercice}}
                                            <v-tooltip top v-if="item.is_exercice_reprise">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}</v-icon>
                                                </template>
                                                <span>Cet exercice est issu d'une reprise de données.</span>
                                            </v-tooltip>
                                        </td>
                                        <td class="text-lg-right">
                                            {{item.participation | roundEuroFilter}}
                                        </td>
                                        <td class="text-lg-right">
                                            <v-row justify="end">
                                                <v-col cols="auto">
                                                    {{item.interets | roundEuroFilter}}
                                                </v-col>
                                            </v-row>
                                        </td>
                                        <td class="text-lg-right">
                                            <v-row justify="end">
                                                <v-col cols="auto">
                                                    {{item.interets_courus | roundEuroFilter}}
                                                </v-col>
                                            </v-row>
                                        </td>
                                        <td class="text-lg-right">
                                            {{item.total | roundEuroFilter}}
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs>
        </v-row>
    </v-container>
</template>

<script>
    import loadingScreen from "@/components/LoadingScreen.vue";
    import internalApi from "@/api/internalApi";

    /**
     * VDatatable pour des opérations simplifiées par date de disponibilité
     * @display_name Participation - VDatatable mouvements simplifiés
     */
    export default {
        name: "VDatatableParticipationInteretsTotaux",
        components: {
            loadingScreen,
        },
        props: {
            height: {
                type: String,
                required: false,
            },
        },
        data() {
            return {
                search: "",
                options: {
                    sortBy: ["date_debut_exercice"],
                    sortDesc: [true],
                },
                headers: [
                    {text: "Exercice", sortable: false, value: "libelle_exercice", align: "left", tooltip: null},
                    {text: "Participation", sortable: false, value: "participation", align: "right", tooltip: null},
                    {text: "Intérêts échus bruts", sortable: false, value: "interets", align: "right", tooltip: "Intérêts de rémunération bruts placés ou versé au titre de l'exercice"},
                    {text: "Intérêts courus bruts", sortable: false, value: "interets_courus", align: "right", tooltip: "Intérêts courus bruts débloqués sur l'exercice"},
                    {text: "Total", sortable: false, value: "total", align: "right", tooltip: null}
                ],
                footerProps: {
                    itemsPerPageOptions: [
                        10,
                        20,
                        50,
                    ],
                },
                showedHeaders: [],
                currentTab: 0,
                participationInteretsTotauxBySupports: [],
                loading: true,
            }
        },
        mounted() {
            this.updateDataTable();
        },
        methods: {
            updateDataTable() {
                this.loading = true;
                internalApi.pbExercice.getAllParticipationInteretsTotaux()
                    .then(result => {
                        this.participationInteretsTotauxBySupports = result;
                    })
                    .catch(err => {
                        console.log(err);
                        this.participationInteretsTotauxBySupports = {};
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    }
</script>