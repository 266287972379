<!-- VUETIFY2 - OK -->
<template>
    <div id="dashboard">
        <loading-screen ref="loadingComponent"/>
        <br/>
        <v-row no-gutters>
            <v-col class="ml-5" md="6" lg="6" xl="6">
                <span>Veuillez sélectionner une date afin de visualiser l'état de votre portefeuille à cette date :</span>
            </v-col>
        </v-row>
        <br/>
        <v-row no-gutters>
            <v-col class="ml-5" md="2" lg="2" xl="2">
                <DateField ref="date_annu"
                           v-model="dateDataCharts"
                           label="Date de référence"
                           :clearable="false"
                           :disabled="false"
                           name="Date de référence"/>
            </v-col>
        </v-row>
        <br/>
        <v-row no-gutters justify="space-around">
            <v-col md="4" lg="4" xl="4">
                <conteneur-graphique type="pieorbar" :title="syntheseRepartitionParticipationDataTitle"
                                     :data="syntheseRepartitionParticipationData"
                                     :date="dateDataCharts"></conteneur-graphique>
            </v-col>
            <v-col md="4" lg="4" xl="4">
                <conteneur-graphique type="line_pb" :title="evolutionParticipationDataTitle"
                                     :data="evolutionParticipationData"
                                     :date="dateDataCharts"></conteneur-graphique>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import moment from "moment/moment";
    import DateField from "@/components/DateField.vue";
    import internalApi from "@/api/internalApi";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import ConteneurGraphique from "@/components/ConteneurGraphique.vue";

    export default {
        components: {
            DateField,
            loadingScreen,
            ConteneurGraphique,
        },
        $_veeValidate: {
            validator: 'new'
        },
        data() {
            return {
                dateDataCharts: moment().format("YYYY-MM-DD"),
                syntheseRepartitionParticipationDataTitle: "Répartition de la participation",
                syntheseRepartitionParticipationData: undefined,
                evolutionParticipationDataTitle: "Evolution de la participation",
                evolutionParticipationData: undefined,
            };
        },
        watch: {
            dateDataCharts: function () {
                this.callBeneficiaireCharts();
            }
        },
        mounted() {
            this.callBeneficiaireCharts();
        },
        methods: {
            callBeneficiaireCharts() {
                if (!_.isNil(this.dateDataCharts)) {
                    this.$refs.loadingComponent.setLoading("Chargement des graphiques");
                    internalApi.pbCharts.beneficiaireChartsIndividuelData(this.dateDataCharts)
                        .then(result => {
                            this.$refs.loadingComponent.resetLoading();
                            this.syntheseRepartitionParticipationData = result.synthese_repartition_participation;
                            this.evolutionParticipationData = result.data_evolution_participation;
                        })
                        .catch(err => {
                            console.error("Erreur lors de la récupération des graphiques : ", err);
                            this.$refs.loadingComponent.resetLoading();
                        });
                }
            },
        }
    };
</script>
