<!-- VUETIFY2 - OK -->
<template>
    <base-layout id="administration" :withFooter="false" headerSize="60px">
        <template v-slot:header>
            <loading-screen ref="loadingComponent"/>
            <v-tabs v-model="currentTab">
                <v-tab key="documents">Paramétrage des documents</v-tab>
                <v-tab key="scic_documents" :disabled="!hasScicExtensionAccess()">Scic</v-tab>
                <v-tab key="data_management" :disabled="!showGestionDonneesTab()">Gestion des données</v-tab>
                <v-tab key="asynchronous_task">Monitoring traitements</v-tab>
                <v-tab key="child_task" v-if="isAdministrateur">Monitoring tâches enfants</v-tab>
            </v-tabs>
        </template>
        <template v-slot:main>
            <v-tabs-items v-model="currentTab">
                <v-tab-item key="documents">
                    <ValidationObserver ref="formDocuments" v-slot="{ reset, validate, errors }">
                        <base-layout :withHeader="false" :withFooter="true">
                            <template v-slot:main>
                                <v-container v-if="isAdministrateur || isResponsable">
                                    <v-row>
                                        <v-col cols="6" v-if="isAdministrateur">
                                            <h3>Tous les documents</h3>
                                            <v-row>
                                                <!-- Petit bouton -->
                                                <v-col cols="6">
                                                <v-import-export-file detail="Importer le logo"
                                                                      :isIcon="false"
                                                                      :typesFile="getTypesExtensionsFiles().img"
                                                                      @import-file="function(formData){onFileChange(formData, 'logo')}"
                                                                      :allowedExport="false"/>
                                                </v-col>
                                                <!-- Petit bouton -->
                                                <v-col cols="6">
                                                    <v-import-export-file detail="Importer le pied de page"
                                                                          :isIcon="false"
                                                                          :typesFile="getTypesExtensionsFiles().img"
                                                                          @import-file="function(formData){onFileChange(formData, 'footer')}"
                                                                          :allowedExport="false"/>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-container>
                                    <v-expansion-panels multiple class="mb-2">
                                        <!--Capital-->
                                        <v-expansion-panel v-if="hasCapitalAccess()">
                                            <v-expansion-panel-header>
                                                <h3>Capital</h3>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content eager>
                                                <v-row justify="center">
                                                    <v-col cols="11">
                                                        <h3>Bulletins de souscription</h3>
                                                        <ValidationProvider vid="texte_libre_bulletin_souscription"
                                                                            name="Texte libre en fin de bulletins de souscription"
                                                                            rules="max:5000"
                                                                            v-slot="{ errors }">
                                                            <v-textarea
                                                                v-model="texte_libre_bulletin_souscription"
                                                                filled
                                                                label="Texte libre en fin de bulletins de souscription"
                                                                auto-grow
                                                                :error-messages="errors[0]"
                                                            ></v-textarea>
                                                        </ValidationProvider>
                                                    </v-col>
                                                </v-row>
                                                <v-row justify="center">
                                                    <v-col cols="11">
                                                        <h3>Bulletins d'annulation</h3>
                                                        <ValidationProvider vid="texte_libre_bulletin_annulation"
                                                                            name="Texte libre en fin de bulletins d'annulation"
                                                                            rules="max:5000"
                                                                            v-slot="{ errors }">
                                                            <v-textarea
                                                                v-model="texte_libre_bulletin_annulation"
                                                                filled
                                                                label="Texte libre en fin de bulletins d'annulation"
                                                                auto-grow
                                                                :error-messages="errors[0]"
                                                            ></v-textarea>
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col cols="11">
                                                        <h3>Fiches individuelles capital</h3>
                                                        <v-row no-gutters>
                                                            <v-col class="shrink text-no-wrap">
                                                                <v-checkbox v-model="affichage_affectation_cap_fiches_individuelles"
                                                                            label="Affichage de l'affectation des intérêts aux parts sociale par défaut"/>
                                                            </v-col>
                                                            <v-col md="1" lg="1" xl="1" class="pl-2">
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}
                                                                        </v-icon>
                                                                    </template>
                                                                    <span
                                                                        v-html="`La désactivation de l’affichage de l’affectation des intérêts aux parts sociale par défaut conduit à masquer les options d’affectation sur la fiche individuelle.`"/>
                                                                </v-tooltip>
                                                            </v-col>
                                                        </v-row>
                                                        <ValidationProvider vid="texte_libre_fiches_individuelles_affectation_interets_parts_sociales"
                                                                            name="Texte libre sur les fiches individuelles concernant les options d’affectation des intérêts aux parts sociales"
                                                                            rules="max:5000"
                                                                            v-slot="{ errors }">
                                                            <v-textarea
                                                                v-model="texte_libre_fiches_individuelles_affectation_interets_parts_sociales"
                                                                filled
                                                                label="Texte libre sur les fiches individuelles concernant les options d’affectation des intérêts aux parts sociales"
                                                                auto-grow
                                                                :error-messages="errors[0]"
                                                            ></v-textarea>
                                                        </ValidationProvider>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <!-- Participation -->
                                        <v-expansion-panel v-if="hasParticipationAccess()" >
                                            <v-expansion-panel-header>
                                                <h3>Participation</h3>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content eager>
                                        <v-row justify="center">
                                            <v-col cols="11">
                                                <h3>Fiches individuelles participation</h3>
                                                <ValidationProvider vid="texte_libre_fiches_individuelles_conditions_repartition_participation"
                                                                    name="Texte libre sur les fiches individuelles concernant les conditions de répartition de la participation"
                                                                    rules="max:5000"
                                                                    v-slot="{ errors }">
                                                    <v-textarea
                                                        v-model="texte_libre_fiches_individuelles_conditions_repartition_participation"
                                                        filled
                                                        label="Texte libre concernant les conditions de répartition de la participation"
                                                        auto-grow
                                                        :error-messages="errors[0]"
                                                    ></v-textarea>
                                                </ValidationProvider>
                                                <v-row no-gutters>
                                                    <v-col class="shrink text-no-wrap">
                                                        <v-checkbox v-model="affichage_affectation_pb_fiches_individuelles"
                                                                    label="Affichage de l'affectation de la participation aux bénéfices par défaut"/>
                                                    </v-col>
                                                    <v-col md="1" lg="1" xl="1" class="pl-2">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}
                                                                </v-icon>
                                                            </template>
                                                            <span
                                                                v-html="`La désactivation de l'affichage de l'affectation de la participation aux bénéfices par défaut conduit à masquer les options d'affectation par défaut sur la fiche individuelle participation`"/>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                                <ValidationProvider vid="texte_libre_fiches_individuelles_options_affectation_participation"
                                                                    name="Texte libre sur les fiches individuelles concernant les options d'affectation de la participation"
                                                                    rules="max:5000"
                                                                    v-slot="{ errors }">
                                                    <v-textarea
                                                        v-model="texte_libre_fiches_individuelles_options_affectation_participation"
                                                        filled
                                                        label="Texte libre sur les fiches individuelles concernant les options d'affectation de la participation aux bénéfices"
                                                        auto-grow
                                                        :error-messages="errors[0]"
                                                    ></v-textarea>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <!--Intéressement-->
                                        <v-expansion-panel v-if="hasInteressementAccess()">
                                            <v-expansion-panel-header>
                                                <h3>Intéressement</h3>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content eager>
                                                <v-row justify="center">
                                                    <v-col cols="11">
                                                        <h3>Fiches individuelles intéressement</h3>
                                                        <v-row no-gutters>
                                                            <v-col class="shrink text-no-wrap">
                                                                <v-checkbox v-model="affichage_affectation_inte_fiches_individuelles"
                                                                            label="Affichage de l’affectation de l’intéressement par défaut"/>
                                                            </v-col>
                                                            <v-col md="1" lg="1" xl="1" class="pl-2">
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}
                                                                        </v-icon>
                                                                    </template>
                                                                    <span
                                                                        v-html="`La désactivation de l’affichage de l’affectation de l’intéressement par défaut conduit à masquer les options d’affectation par défaut sur les fiches individuelles`"/>
                                                                </v-tooltip>
                                                            </v-col>
                                                        </v-row>
                                                        <ValidationProvider vid="texte_libre_fiches_individuelles_options_affectation_interessement"
                                                                            name="Texte libre sur les fiches individuelles concernant les options d’affectation de l’intéressement"
                                                                            rules="max:5000"
                                                                            v-slot="{ errors }">
                                                            <v-textarea
                                                                v-model="texte_libre_fiches_individuelles_options_affectation_interessement"
                                                                filled
                                                                label="Texte libre sur les fiches individuelles concernant les options d’affectation de l’intéressement"
                                                                auto-grow
                                                                :error-messages="errors[0]"
                                                            ></v-textarea>
                                                        </ValidationProvider>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-container>
                            </template>
                            <template v-slot:footer>
                                <v-row no-gutters justify="end">
                                    <!-- Petits boutons -->
                                    <v-col md="6" lg="6" xl="4">
                                        <v-save-buton-with-validation :resetForm="reset"
                                                                      :validateForm="validate"
                                                                      :errorsList="errors"
                                                                      @emit-cancel="cancelDocuments()"
                                                                      @emit-valid-save="saveDocuments()"/>
                                    </v-col>
                                </v-row>
                            </template>
                        </base-layout>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item key="scic_documents">
                    <v-scic-administration v-if="hasScicExtensionAccess()"/>
                </v-tab-item>
                <v-tab-item key="data_management">
                    <data-management/>
                </v-tab-item>
                <v-tab-item key="asynchronous_task">
                    <asynchronous-batch-task-monitoring/>
                </v-tab-item>
                <v-tab-item key="child_task" v-if="isAdministrateur">
                    <asynchronous-task-monitoring v-if="isAdministrateur"/>
                </v-tab-item>
            </v-tabs-items>
        </template>
    </base-layout>
</template>

<script>
import internalApi from "@/api/internalApi";
import Utils from "@/utils";
import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue"
import BaseLayout from "@/components/BaseLayout";
import _ from "lodash";
import authService from "@/services/authService";
import rolesList from "@/router/rolesList";
import VScicAdministration from "@/components/scic/VScicAdministration";
import AsynchronousBatchTaskMonitoring from "@/views/administration/AsynchronousBatchTaskMonitoring";
import AsynchronousTaskMonitoring from "@/views/administration/AsynchronousTaskMonitoring";
import DataManagement from "@/views/administration/DataManagement";
import constantes from "@/utils/constantes";
import VImportExportFile from "@/components/VImportExportFile";
import loadingScreen from "@/components/LoadingScreen.vue";

export default {
    components: {
        DataManagement,
        AsynchronousTaskMonitoring,
        AsynchronousBatchTaskMonitoring,
        VScicAdministration,
        BaseLayout,
        VSaveButonWithValidation,
        VImportExportFile,
        loadingScreen,
    },
    data() {
        return {
            currentTab: undefined,
            isAdministrateur: false,
            isResponsable: false,
            formData: new FormData(),
            texte_libre_bulletin_souscription: "",
            texte_libre_bulletin_annulation: "",
            texte_libre_fiches_individuelles_conditions_repartition_participation: "",
            texte_libre_fiches_individuelles_options_affectation_participation: "",
            affichage_affectation_pb_fiches_individuelles: false,
            texte_libre_fiches_individuelles_affectation_interets_parts_sociales: "",
            affichage_affectation_cap_fiches_individuelles: false,
            texte_libre_fiches_individuelles_options_affectation_interessement: "",
            affichage_affectation_inte_fiches_individuelles: false,
            exportAllDataDetail: "Extraction de toutes les données client",
        }
    },
    mounted() {
        this.fetchDocuments();
        const currentRoles = authService.getCurrentRoles();
        this.isAdministrateur = !_.isEmpty(_.intersection([rolesList.ADMINISTRATEUR], currentRoles));
        this.isResponsable = !_.isEmpty(_.intersection([rolesList.RESPONSABLE], currentRoles));
    },
    computed: {
        allowImportFichierInitial() {
            return ! Boolean(this.$store.getters.getCompanyName());
        },
    },
    methods: {
        getTypesExtensionsFiles() {
            return constantes.extensions_fichiers;
        },
        hasCapitalAccess: function () {
            return authService.hasAccessCapital();
        },
        hasParticipationAccess: function () {
            return authService.hasAccessParticipation();
        },
        hasInteressementAccess: function () {
            return authService.hasAccessInteressement();
        },
        hasScicExtensionAccess() {
            return authService.checkHasScicExtensionAccess();
        },
        fetchDocuments() {
            internalApi.administration.getDocumentsFields().then(result => {
                result.forEach(free_text => {
                    switch(free_text["key"]) {
                        case "free_text_BS":
                            this.texte_libre_bulletin_souscription = free_text["string"];
                            break;
                        case "free_text_BA":
                            this.texte_libre_bulletin_annulation = free_text["string"];
                            break;
                        case "free_text_FI_conditions_repartition_participation":
                            this.texte_libre_fiches_individuelles_conditions_repartition_participation = free_text["string"];
                            break;
                        case "free_text_FI_options_affectation_participation":
                            this.texte_libre_fiches_individuelles_options_affectation_participation = free_text["string"];
                            break;
                        case "FI_affichage_affectation_defaut":
                            this.affichage_affectation_pb_fiches_individuelles = free_text["boolean"];
                            break;
                        case "free_text_FI_affectation_interets_parts_sociales":
                            this.texte_libre_fiches_individuelles_affectation_interets_parts_sociales = free_text["string"];
                            break;
                        case "FI_affichage_affectation_des_interets_aux_parts_sociale_defaut":
                            this.affichage_affectation_cap_fiches_individuelles = free_text["boolean"] ;
                            break;
                        case "free_text_FI_options_affectation_interessement":
                            this.texte_libre_fiches_individuelles_options_affectation_interessement = free_text["string"];
                            break;
                        case "FI_affichage_inte_affectation_defaut":
                            this.affichage_affectation_inte_fiches_individuelles = free_text["boolean"];
                            break;
                    }
                });
            }).catch(err => {
                console.log(err);
            });
        },
        saveDocuments() {
            let payload = [
                {
                    "key": "free_text_BS",
                    "string": this.texte_libre_bulletin_souscription,
                    "boolean": null,
                },
                {
                    "key": "free_text_BA",
                    "string": this.texte_libre_bulletin_annulation,
                    "boolean": null,
                },
                {
                    "key": "free_text_FI_conditions_repartition_participation",
                    "string": this.texte_libre_fiches_individuelles_conditions_repartition_participation,
                    "boolean": null,
                },
                {
                    "key": "free_text_FI_options_affectation_participation",
                    "string": this.texte_libre_fiches_individuelles_options_affectation_participation,
                    "boolean": null,
                },
                {
                    "key": "FI_affichage_affectation_defaut",
                    "string": null,
                    "boolean": this.affichage_affectation_pb_fiches_individuelles,
                },
                {
                    "key": "free_text_FI_affectation_interets_parts_sociales",
                    "string": this.texte_libre_fiches_individuelles_affectation_interets_parts_sociales,
                    "boolean": null,
                },
                {
                    "key": "FI_affichage_affectation_des_interets_aux_parts_sociale_defaut",
                    "string": null,
                    "boolean": this.affichage_affectation_cap_fiches_individuelles,
                },
                {
                    "key": "free_text_FI_options_affectation_interessement",
                    "string": this.texte_libre_fiches_individuelles_options_affectation_interessement,
                    "boolean": null,
                },
                {
                    "key": "FI_affichage_inte_affectation_defaut",
                    "string": null,
                    "boolean": this.affichage_affectation_inte_fiches_individuelles,
                },

            ]

            internalApi.administration.postDocumentsFields(payload)
                .catch(err => {
                    console.error(err);
                });
        },
        cancelDocuments() {
            this.fetchDocuments();
        },
        onFileChange(formData, type) {
            let file = formData.get("file");
            if (!_.isNil(file)) {
                this.formData = formData
                this.uploadFile(type);
            }
        },
        uploadFile(type) {
            internalApi.administration.uploadAdminImage(type, this.formData)
                .then(() => {
                    this.$emit("new-uploaded-doc");
                })
                .catch(err => {
                    console.error(err)
                });
        },
        uploadFileInitial(formData) {
            internalApi.administration.uploadFileInitial(formData)
                .then(() => {
                    this.$emit("new-uploaded-doc");
                })
                .catch(err => {
                    console.error(err)
                });
        },
        downloadFileInitial() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
            return internalApi.administration.downloadFileInitial()
                .then(response => {
                    Utils.downloadFile(response, "export_global_" + new Date().toISOString().slice(0, 10) + ".xlsx", Utils.excelMimetype);
                })
                .finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
        },
        downloadModelFileInitial() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
            return internalApi.administration.downloadModelFileInitial()
                .then(response => {
                    Utils.downloadFile(response, "modele_fichier_initial.xlsx", Utils.excelMimetype);
                })
                .finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
        },
        
        showGestionDonneesTab() {
            return (this.isAdministrateur || this.isResponsable);
        },
    }
}
</script>
