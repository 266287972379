<template>
    <ValidationObserver v-slot="{ validate, errors }">
        <base-layout :withFooter="false" headerSize="150px">
            <template v-slot:header>
                <loading-screen ref="loadingComponent"/>
                <v-row>
                    <v-col md="4" lg="4" xl="4">
                        <span>Veuillez sélectionner un exercice afin de télécharger les documents correspondants :</span>
                        <br/>
                        <ValidationProvider vid="selectedExercice" name="Exercice"
                                            rules="required"
                                            v-slot="{ errors }">
                            <v-exercices-list :value="selectedExercice"
                                              @input="selectedExerciceChange($event)"
                                              :errors="errors"
                                              ref="exercices"/>
                        </ValidationProvider>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:main>
                <v-row no-gutters class="ml-2" align="center" align-content="end">
                    <v-col md="2" lg="2" xl="2" class="text-right mr-2">
                        <h3>Attestation fiscale :</h3>
                    </v-col>
                    <!-- Petit bouton -->
                    <v-col md="3" lg="3" xl="2">
                        <v-button-with-validation :validateForm="validate"
                                                 :errorsList="errors"
                                                 @emit-valid-click="downloadAttestationFiscale"
                                                 btnLabel="Télécharger"
                        />
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
import internalApi from "@/api/internalApi";
import constantes from "@/utils/constantes";
import Utils from "@/utils";
import loadingScreen from "@/components/LoadingScreen.vue";
import VExercicesList from "@/components/VExercicesList";
import BaseLayout from "@/components/BaseLayout";
import VButtonWithValidation from "@/components/VButtonWithValidation";

export default {
    name: "VScicEspaceAssocie",
    components: {
        loadingScreen,
        VExercicesList,
        BaseLayout,
        VButtonWithValidation,
    },
    data() {
        return {
            selectedExercice: undefined,
        }
    },
    methods: {
        downloadAttestationFiscale() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
            internalApi.scicDocuments.getAttestationFiscale(this.selectedExercice)
                .then(response => {
                    this.$refs.loadingComponent.resetLoading();
                    Utils.downloadFile(response, "Attestation_fiscale.pdf", Utils.pdfMimetype);
                })
                .catch(err => {
                    console.error(err);
                    this.$refs.loadingComponent.resetLoading();
                });
        },
        selectedExerciceChange(newSelectedExerciceId) {
            this.selectedExercice = newSelectedExerciceId;
        },
    }
}
</script>
