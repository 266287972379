<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver v-slot="{ validate, errors, reset }">
        <base-layout :withFooter="false" headerSize="140px">
            <template v-slot:header>
                <h1>Exercice</h1>
                <v-tabs v-model="currentTab">
                    <v-tab key="donnees_exercice">Données exercice</v-tab>
                    <v-tab key="periodes_travail" :disabled="creationMode">Périodes de travail</v-tab>
                </v-tabs>
            </template>
            <template v-slot:main>
                <loading-screen ref="loadingComponent"/>
                <v-tabs-items v-model="currentTab">
                    <v-tab-item key="donnees_exercice">
                        <base-layout :withHeader="false">
                            <template v-slot:main>
                                <v-container>
                                    <common-exercice :date_debut="exercice.date_debut"
                                                     :date_fin="exercice.date_fin"
                                                     :date_ag_annuelle="exercice.date_ag_annuelle"
                                                     :libelle="exercice.libelle"
                                                     :benefices="exercice.benefices"
                                                     :montant_participation_brute="exercice.participation_brute"
                                                     @update-date-debut="exercice.date_debut = $event"
                                                     @update-date-fin="onDateFinChange($event)"
                                                     @update-date-ag-annuelle="exercice.date_ag_annuelle = $event"
                                                     @update-libelle="exercice.libelle = $event"
                                                     @update-montant-participation-brute="exercice.participation_brute = $event"
                                                     @update-benefices="exercice.benefices = $event"
                                                     :exerciceValide="isRepartitionValide || exercice.is_common_exercice_any_state_valide">
                                    </common-exercice>
                                    <h3 slot="header">Contrôles plafonds</h3>
                                    <v-container pt-0>
                                        <v-row justify="space-between" dense>
                                            <v-col md="5" lg="5" xl="5">
                                                <v-row align="center" no-gutters>
                                                    <v-col class="shrink text-no-wrap">
                                                        <h4>Contrôle PASS</h4>
                                                    </v-col>
                                                    <v-col md="1" lg="1" xl="1" class="pl-2">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}
                                                                </v-icon>
                                                            </template>
                                                            <span
                                                                v-html="`Participation aux bénéfices individuelle plafond : 0,75 × Plafond Annuel de la Sécurité Sociale​<br/>
                                                                         Participation aux bénéfices commune plafond : 0,75 × Plafond Annuel de la Sécurité Sociale × Nombre de bénéficiaires<br/>
                                                                         Salaire plafond de la répartition selon le salaire : 3 × Plafond Annuel de la Sécurité Sociale`"/>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col md="5" lg="5" xl="5" v-if="isScop">
                                                <v-row align="center" no-gutters>
                                                    <v-col class="shrink text-no-wrap">
                                                        <h4>Contrôle SCOP</h4>
                                                    </v-col>
                                                    <v-col md="1" lg="1" xl="1" class="pl-2">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}
                                                                </v-icon>
                                                            </template>
                                                            <span
                                                                v-html="`Participation aux bénéfices plafond 1 : 0,5 × Bénéfices nets<br/>
                                                                         Participation aux bénéfices plafond 2 : Bénéfices nets - 0,05 × Capital libéré`"/>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="space-between" dense>
                                            <v-col cols="5">
                                                <v-row align="center" no-gutters>
                                                    <v-col>
                                                        <ValidationProvider vid="plafond_annuel_securite_sociale"
                                                                            name="Plafond Annuel de la Sécurité Sociale"
                                                                            ref="plafond_annuel_securite_sociale"
                                                                            rules="required|currency|min_value:0"
                                                                            v-slot="{ errors }">
                                                            <v-formatted-number-text-field
                                                                v-model="exercice.plafond_annuel_securite_sociale"
                                                                prefixValue="€"
                                                                labelValue="Plafond Annuel de la Sécurité Sociale"
                                                                :reverseValue="true"
                                                                :errors="errors"
                                                                :disabledValue="isRepartitionValide || !canEditPass"
                                                            />
                                                        </ValidationProvider>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="5" v-if="isScop">
                                                <v-row align="center" no-gutters>
                                                    <v-col>
                                                        <ValidationProvider vid="capital_libere"
                                                                            name="Capital libéré"
                                                                            ref="capital_libere"
                                                                            rules="currency|min_value:0"
                                                                            v-slot="{ errors }">
                                                            <v-formatted-number-text-field
                                                                v-model="exercice.pb_exercice.capital_libere"
                                                                prefixValue="€"
                                                                labelValue="Capital libéré"
                                                                :reverseValue="true"
                                                                :errors="errors"
                                                                :disabledValue="isRepartitionValide || hasAccessCapital"
                                                            />
                                                        </ValidationProvider>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="space-between" dense>
                                            <v-col cols="5">
                                                <v-row no-gutters>
                                                    <v-col>
                                                        Participation aux bénéfices individuelle plafond&nbsp;:
                                                        <span class="text-no-wrap" v-if="areCorrect([plafond_pb_individuel])">{{plafond_pb_individuel | roundEuroFilter}}</span>
                                                        <span v-else class="orange--text">{{computeOrangeTextControlPlafondAnnuelSecuriteSociale()}}</span>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col>
                                                        Participation aux bénéfices commune plafond&nbsp;:
                                                        <span class="text-no-wrap" v-if="areCorrect([plafond_pb_commun])">{{plafond_pb_commun | roundEuroFilter}}</span>
                                                        <span v-else class="orange--text">{{computeOrangeTextControlPlafondAnnuelSecuriteSociale()}}</span>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col>
                                                        Salaire plafond&nbsp;:
                                                        <span v-if="areCorrect([plafond_remuneration_salaire])">
                                                            <span class="text-no-wrap">
                                                                {{plafond_remuneration_salaire | roundEuroFilter}}
                                                            </span>
                                                            &nbsp;pour un salarié ayant travaillé sur tout l'exercice.
                                                        </span>
                                                        <span v-else class="orange--text">{{computeOrangeTextControlPlafondAnnuelSecuriteSociale()}}</span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="5" v-if="isScop">
                                                <v-row no-gutters>
                                                    <v-col>
                                                        Participation aux bénéfices plafond 1 (Bénéfice)&nbsp;:
                                                        <span class="text-no-wrap" v-if="areCorrect([montantPbPlafondScopBeneficeOnly])">{{montantPbPlafondScopBeneficeOnly | roundEuroFilter}}</span>
                                                        <span v-else class="orange--text">{{orangeTextMontantPartTravailPlafondEtPlancher}}</span>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col>
                                                        ou
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col>
                                                        Participation aux bénéfices plafond 2 (Bénéfice avec impact Capital)&nbsp;:
                                                        <span class="text-no-wrap" v-if="areCorrect([montantPbPlafondScopBeneficeImpactCapital])">{{montantPbPlafondScopBeneficeImpactCapital | roundEuroFilter}}</span>
                                                        <span v-else class="orange--text">{{computeOrangeTextMontantPartTravailPlafond2Scop()}}</span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <h3 slot="header">Contrôles planchers</h3>
                                    <v-container pt-0>
                                        <v-row justify="space-between" dense>
                                            <v-col md="5" lg="5" xl="5">
                                                <v-row align="center" no-gutters>
                                                    <v-col class="shrink text-no-wrap">
                                                        <h4>Contrôle légal</h4>
                                                    </v-col>
                                                    <v-col md="1" lg="1" xl="1" class="pl-2">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}
                                                                </v-icon>
                                                            </template>
                                                            <span
                                                                v-html="`Participation aux bénéfices minimale : 0,5 × (0,75 × Bénéfices nets - 0,05 × Capital libéré) × (salaires bruts / valeur ajoutée)`"/>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        Participation aux bénéfices plancher&nbsp;:
                                                        <span class="text-no-wrap" v-if="areCorrect([montantPbMinLegal])">{{montantPbMinLegal | roundEuroFilter}}</span>
                                                        <span v-else class="orange--text">{{computeOrangeTextMontantPartTravailPlancher()}}</span>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col>
                                                        <ValidationProvider vid="valeur_ajoutee"
                                                                            name="Valeur ajoutée"
                                                                            rules="currency|min_value:1"
                                                                            v-slot="{ errors }">
                                                            <v-formatted-number-text-field
                                                                v-model="exercice.pb_exercice.valeur_ajoutee"
                                                                prefixValue="€"
                                                                labelValue="Valeur ajoutée"
                                                                :reverseValue="true"
                                                                :errors="errors"
                                                                :disabledValue="isRepartitionValide"/>
                                                        </ValidationProvider>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col>
                                                        <ValidationProvider vid="masse_salariale"
                                                                            name="Salaires bruts"
                                                                            rules="currency|min_value:0"
                                                                            v-slot="{ errors }">
                                                            <v-formatted-number-text-field
                                                                v-model="exercice.masse_salariale"
                                                                prefixValue="€"
                                                                labelValue="Salaires bruts"
                                                                :reverseValue="true"
                                                                :errors="errors"
                                                                :disabledValue="isRepartitionValide || !canEditMasseSalariale"/>
                                                        </ValidationProvider>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters v-if="!isScop">
                                                    <v-col>
                                                        <ValidationProvider vid="capital_libere"
                                                                    name="Capital libéré"
                                                                    ref="capital_libere"
                                                                    rules="currency|min_value:0"
                                                                    v-slot="{ errors }">
                                                            <v-formatted-number-text-field
                                                                v-model="exercice.pb_exercice.capital_libere"
                                                                prefixValue="€"
                                                                labelValue="Capital libéré"
                                                                :reverseValue="true"
                                                                :errors="errors"
                                                                :disabledValue="isRepartitionValide || hasAccessCapital"
                                                            />
                                                        </ValidationProvider>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col md="5" lg="5" xl="5" v-if="isScop">
                                                <v-row align="center" no-gutters>
                                                    <v-col class="shrink text-no-wrap">
                                                        <h4>Contrôle SCOP</h4>
                                                    </v-col>
                                                    <v-col md="1" lg="1" xl="1" class="pl-2">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}
                                                                </v-icon>
                                                            </template>
                                                            <span
                                                                v-html="`Participation aux bénéfices minimale en SCOP : 0,25 × Bénéfices nets`"/>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        Participation aux bénéfices plancher&nbsp;:
                                                        <span class="text-no-wrap" v-if="areCorrect([montantPbMinScop])">{{montantPbMinScop | roundEuroFilter}}</span>
                                                        <span v-else class="orange--text">{{orangeTextMontantPartTravailPlafondEtPlancher}}</span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <h3 slot="header">Placement de la participation</h3>
                                    <v-container pt-0>
                                        <v-row justify="space-between" dense>
                                            <v-col md="6" lg="6" xl="6">
                                                <v-row align="center">
                                                    <v-col md="10" lg="10" xl="10">
                                                        <ValidationProvider vid="date_placement_pb"
                                                                            name="Date de placement"
                                                                            rules="required"
                                                                            v-slot="{ errors }">
                                                            <date-field v-model="exercice.pb_exercice.date_placement_pb"
                                                                        label="Date de placement"
                                                                        :errors="errors"
                                                                        :disabled="isAffectationValide"
                                                            />
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col>
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}</v-icon>
                                                            </template>
                                                            <span
                                                                v-html="`La date de placement de la participation détermine la date à partir de laquelle la participation est placée. Cette date détermine également le début du blocage de 5 ans.`"/>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="space-between" align="center" dense>
                                            <v-col md="5" lg="5" xl="5">
                                                <v-checkbox v-model="hasSupportDeversement"
                                                            label="Support de déversement par défaut"
                                                            @change="switchHasSupportDeversement"
                                                            :disabled="isAffectationValide"/>
                                            </v-col>
                                            <v-col md="5" lg="5" xl="5">
                                                <ValidationProvider vid="support_deversement_id"
                                                                    name="Support"
                                                                    :rules="{ required: hasSupportDeversement }"
                                                                    v-slot="{ errors }">
                                                    <v-pb-supports-list v-model="exercice.pb_exercice.support_deversement_id"
                                                                     label="Support"
                                                                     :supportFilter="'actif'"
                                                                     :errors="errors"
                                                                     :isEnabled="hasSupportDeversement && !isAffectationValide"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <h3 slot="header">Affectation de la participation</h3>
                                    <v-container pt-0>
                                        <v-row justify="space-between" dense>
                                            <v-col md="5" lg="5" xl="5">
                                                <v-checkbox v-model="exercice.pb_exercice.allows_edit_versement_immediat"
                                                            label="Versement immédiat"
                                                            :disabled="isAffectationValide"/>
                                            </v-col>
                                            <v-col>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon small left v-on="on"> {{ canEditVersementImmediat ? "$vuetify.icons.droitsAccesDeverrouille" : "$vuetify.icons.droitsAccesVerrouille"}}</v-icon>
                                                    </template>
                                                    <span 
                                                        v-html="getTooltipTextIconeCadenas(canEditVersementImmediat)"/>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="space-between" dense>
                                            <v-col md="5" lg="5" xl="5">
                                                <v-checkbox v-model="exercice.pb_exercice.allows_edit_montee_capital"
                                                            label="Montée au capital"
                                                            :disabled="isAffectationValide"/>
                                            </v-col>
                                            <v-col>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon small left v-on="on"> {{ canEditMonteeCapital ? "$vuetify.icons.droitsAccesDeverrouille" : "$vuetify.icons.droitsAccesVerrouille"}}</v-icon>
                                                    </template>
                                                    <span 
                                                        v-html="getTooltipTextIconeCadenas(canEditMonteeCapital)"/>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-container>
                            </template>
                            <template v-slot:footer>
                                <v-row no-gutters>
                                    <v-spacer/>
                                    <!-- Petits boutons -->
                                    <v-col v-if="!isAffectationValide" md="6" lg="6" xl="4">
                                        <v-save-buton-with-validation :validateForm="validate"
                                                                      :resetForm="reset"
                                                                      :errorsList="errors"
                                                                      @emit-cancel="returnToExerciceList"
                                                                      @emit-valid-save="save"/>
                                    </v-col>
                                    <!-- Petit bouton -->
                                    <v-col v-else md="3" lg="3" xl="2">
                                        <v-btn block @click="returnToExerciceList" color="primary">Retour</v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                        </base-layout>
                    </v-tab-item>
                    <v-tab-item key="periodes_travail">
                        <pb-donnees-beneficiaires :exercice="exercice"
                                                  :disabled="isRepartitionValide"
                                                  :showSalairesPresences="showSalairesPresences"
                        />
                    </v-tab-item>
                </v-tabs-items>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
    import authService from "@/services/authService";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
    import internalApi from "@/api/internalApi";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";
    import Utils from "@/utils.js";
    import BaseLayout from "@/components/BaseLayout";
    import CommonExercice from "@/components/exercice/CommonExercice";
    import VPbSupportsList from "@/components/supportsList/VPbSupportsList";
    import DateField from "@/components/DateField";
    import moment from "moment";
    import PbDonneesBeneficiaires from "@/views/common/exercice/PbDonneesBeneficiaires";
    import rolesList from "@/router/rolesList";
    import _ from "lodash";

    /**
     * Composant affichant les informations liées à un exercice côté participation
     * @displayName Participation - PbExercice
     */
    export default {
        components: {
            PbDonneesBeneficiaires,
            DateField,
            VFormattedNumberTextField,
            VSaveButonWithValidation,
            loadingScreen,
            BaseLayout,
            CommonExercice,
            VPbSupportsList,
        },
        props: {
            /**
             * Détermine si on est en train de créer un nouvel exercice ou de modifier
             * un existant
             */
            creationMode: undefined
        },
        data() {
            return {
                currentTab: undefined,
                exercice: {
                    is_common_exercice_any_state_valide: true,
                    pb_exercice: {},
                },
                isRepartitionValide: true,
                canEditMasseSalariale: false,
                canEditPass: false,
                isAffectationValide: true,
                showSalairesPresences: false,
                montantPbMinLegal: undefined,
                montantPbMinScop: undefined,
                montantPbPlafondScopBeneficeOnly: undefined,
                montantPbPlafondScopBeneficeImpactCapital: undefined,
                debouncedMontantPbMinLegal: undefined,
                debouncedMontantPbMinScop: undefined,
                debouncedMontantPbPlafondScopBeneficeOnly: undefined,
                debouncedMontantPbPlafondScopBeneficeImpactCapital: undefined,
                debouncedPassPlafondsIndivEtCommun: undefined,
                debouncedMontantRenumerationSalairePlafond: undefined,
                hasSupportDeversement: false,
                plafond_pb_individuel: undefined,
                plafond_pb_commun: undefined,
                plafond_remuneration_salaire: undefined,
                company: {
                    legal_coop_type: "",
                },
                orangeTextMontantPartTravailPlafondEtPlancher: "Renseigner les bénéfices pour activer ce contrôle.",
                droits_acces: [],
                canEditVersementImmediat: false,
                canEditMonteeCapital: false,
               
            };
        },
        computed: {
            isScop() {
                return _.includes(this.company.legal_coop_type, "scop");
            },
            hasAccessCapital() {
                return authService.hasAccessCapital();
            },
            hasAccessInteressement() {
                return authService.hasAccessInteressement();
            },
        },
        mounted() {

            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
            let currentRoles = authService.getCurrentRoles();
            this.showSalairesPresences = currentRoles.includes(rolesList.RESPONSABLE)
            let promises = [];

            const delayInMillis = 500;
            this.debouncedMontantPbMinLegal = _.debounce(this.computeMontantPbMinLegal, delayInMillis);
            this.debouncedMontantPbMinScop = _.debounce(this.computeMontantPbMinScop, delayInMillis);
            this.debouncedMontantPbPlafondScopBeneficeOnly = _.debounce(this.computeMontantPbPlafondScopBeneficeOnly, delayInMillis);
            this.debouncedMontantPbPlafondScopBeneficeImpactCapital = _.debounce(this.computeMontantPbPlafondScopBeneficeImpactCapital, delayInMillis);
            this.debouncedPassPlafondsIndivEtCommun = _.debounce(this.getPassPlafondsIndivEtCommun, delayInMillis);
            this.debouncedMontantRenumerationSalairePlafond = _.debounce(this.getMontantRenumerationSalairePlafond, delayInMillis);

            let promise_company = this.getCompany();
            promises.push(promise_company);

            if (!this.creationMode) {
                const {exerciceId} = this.$route.params;
                let promise_exercice = this.getPbExerciceById(exerciceId).then(result => {
                    this.isRepartitionValide = this.exercice.pb_exercice.is_repartition_pb_valide;
                    this.canEditMasseSalariale = this.exercice.can_edit_masse_salariale;
                    this.canEditPass = this.exercice.can_edit_pass;
                    this.isAffectationValide = this.exercice.pb_exercice.is_ventilation_pb_valide;
                    this.hasSupportDeversement = this.exercice.pb_exercice.support_deversement_id !== null
                });
                promises.push(promise_exercice);
            } else {
                this.exercice = {
                    date_debut: undefined,
                    date_fin: undefined,
                    date_ag_annuelle: undefined,
                    libelle: undefined,
                    benefices: undefined,
                    participation_brute: undefined,
                    masse_salariale: undefined,
                    plafond_annuel_securite_sociale: undefined,
                    pb_exercice: {
                        valeur_ajoutee: undefined,
                        support_deversement_id: undefined,
                        allows_edit_versement_immediat: true,
                        allows_edit_montee_capital: true,
                        date_placement_pb: undefined,
                        capital_libere: undefined,
                    },
                }
                this.isRepartitionValide = false;
                this.canEditMasseSalariale = true;
                this.canEditPass = true;
                this.isAffectationValide = false;
            }

            Promise.all(promises).finally(() => {
                this.$refs.loadingComponent.resetLoading();
            });
            this.canEditVersementImmediat = this.$store.getters.hasDroitAcces(
                constantes.droits_acces.noms.espace_pb_affectation_versement_immediat,
                [constantes.droits_acces.valeurs_possibles.edition],
            )
            this.canEditMonteeCapital = this.$store.getters.hasDroitAcces(
                constantes.droits_acces.noms.espace_pb_affectation_montee_capital,
                [constantes.droits_acces.valeurs_possibles.edition],
            )

        },
        watch: {
            'exercice.benefices': function () {
                this.debouncedMontantPbMinScop();
                this.debouncedMontantPbPlafondScopBeneficeOnly();
                this.debouncedMontantPbPlafondScopBeneficeImpactCapital();
                this.debouncedMontantPbMinLegal();
            },
            'exercice.pb_exercice.capital_libere': function() {
                this.debouncedMontantPbPlafondScopBeneficeImpactCapital();
                this.debouncedMontantPbMinLegal();
            },
            'exercice.masse_salariale': function () {
                this.debouncedMontantPbMinLegal();
            },
            'exercice.pb_exercice.valeur_ajoutee': function () {
                this.debouncedMontantPbMinLegal();
            },
            'exercice.plafond_annuel_securite_sociale': function (new_value) {
                this.$refs["plafond_annuel_securite_sociale"].validate(new_value).then(
                    res => {
                        if (res.valid) {
                            this.debouncedPassPlafondsIndivEtCommun(new_value);
                            this.debouncedMontantRenumerationSalairePlafond(new_value);
                        }
                    }
                )
            },
            'exercice.date_debut': function () {
                if (this.creationMode) {
                    this.getPassPlafondsIndivEtCommun();
                }
            },
            'exercice.date_fin': function () {
                if (this.creationMode) {
                    this.getPassPlafondsIndivEtCommun();
                }
                if (this.hasAccessCapital) {
                    if (_.isNil(this.exercice.date_fin)){
                        this.exercice.pb_exercice.capital_libere = 0;
                    } else {
                        internalApi.capital.getCapitalADate(this.exercice.date_fin)
                            .then(res => {
                                this.exercice.pb_exercice.capital_libere = res.capital_a_date;
                            });
                    }
                }
            },
        },
        methods: {
            getPbExerciceById(pb_exercice_id) {
                return internalApi.pbExercice.getById(pb_exercice_id).then(
                    result => {
                        this.exercice = result;
                    }
                );
            },
            getCompany() {
                return internalApi.company.getVersionCompany()
                    .then(result => {
                        this.company = result;
                    });
            },
            getMontantRenumerationSalairePlafond(pass=null) {
                if (pass == null) {
                    pass = this.exercice.plafond_annuel_securite_sociale;
                }
                const data_to_compute = {
                    "plafond_annuel_securite_sociale": pass
                }

                internalApi.pbExercice.getMontantRenumerationSalairePlafond(data_to_compute)
                    .then(response => {
                        this.plafond_remuneration_salaire = response.plafond_remuneration_selon_salaire;
                    });
            },
            getPassPlafondsIndivEtCommun(pass=null) {
                if (pass == null) {
                    pass = this.exercice.plafond_annuel_securite_sociale;
                }
                let pb_exercice_id = this.exercice.pb_exercice.id;

                if (_.isNil(pass)) {
                    pass = 0;
                }

                let data_to_compute = {
                    "plafond_annuel_securite_sociale": pass
                }
                if (this.creationMode || _.isNil(pb_exercice_id)) {

                    data_to_compute["date_debut"] = this.exercice.date_debut;
                    data_to_compute["date_fin"] = this.exercice.date_fin;

                    if (_.isNil(data_to_compute["date_debut"]) || _.isNil(data_to_compute["date_fin"])) {
                        data_to_compute["date_debut"] = null;
                        data_to_compute["date_fin"] = null;
                    }

                    internalApi.pbExercice.getPassPlafondsIndivEtCommunNoExercice(data_to_compute)
                        .then(response => {
                            this.plafond_pb_individuel = response.plafond_pb_individuel;
                            this.plafond_pb_commun = response.plafond_pb_commun;
                        });
                } else {
                    internalApi.pbExercice.getPassPlafondsIndivEtCommun(pb_exercice_id, data_to_compute)
                        .then(response => {
                            this.plafond_pb_individuel = response.plafond_pb_individuel;
                            this.plafond_pb_commun = response.plafond_pb_commun;
                        });
                }
            },
            /**
             * Vérifie que chaque entrée d'une liste est non vide et est un nombre
             * @param fields la liste des champs à vérifier
             * @returns {boolean}
             */
            areCorrect(fields = []) {
                let areCorrect = true;
                fields.forEach(function (element) {
                    if (_.isNil(element) || element === "" || isNaN(element)) {
                        areCorrect = false;
                    }
                });
                return areCorrect;
            },
            /**
             * Calculer le message orange à partir d'une liste d'éléments
             * @param elements
             */
            computeOrangeTextMessageFromElements(elements) {
                if (elements.length > 0) {
                    return "Renseigner " + Utils.formatListToFrench(elements) + " pour activer ce contrôle.";
                } else {
                    return ""
                }
            },
            /**
             * Générer des messages d'avertissements personnalisés pour la partie contrôle des planchers
             * @returns {string}
             */
            computeOrangeTextMontantPartTravailPlancher() {
                let messages_champs_requis = []
                if (_.isNil(this.exercice.benefices)) {
                    messages_champs_requis.push("les bénéfices");
                }
                if (_.isNil(this.exercice.pb_exercice.valeur_ajoutee)) {
                    messages_champs_requis.push("la valeur ajoutée");
                }
                if (_.isNil(this.exercice.pb_exercice.masse_salariale)) {
                    messages_champs_requis.push("les salaires bruts");
                }
                if (_.isNil(this.exercice.pb_exercice.capital_libere)) {
                    messages_champs_requis.push("le capital libéré");
                }
                return this.computeOrangeTextMessageFromElements(messages_champs_requis);
            },
            /**
             * Générer des messages d'avertissements personnalisés pour la partie contrôle des scops avec un message capital libéré
             * @returns {string}
             */
            computeOrangeTextControlPlafondAnnuelSecuriteSociale() {
                let messages_champs_requis = []
                if (_.isNil(this.exercice.pb_exercice.plafond_annuel_securite_sociale) && _.isNil(this.exercice.pb_exercice.plafond_pb_commun)) {
                    messages_champs_requis.push("le Plafond Annuel de la Sécurité Sociale");
                }
                return this.computeOrangeTextMessageFromElements(messages_champs_requis);
            },
            computeOrangeTextMontantPartTravailPlafond2Scop() {
                let messages_champs_requis = []
                if (_.isNil(this.exercice.benefices)) {
                    messages_champs_requis.push("les bénéfices");
                }
                if (_.isNil(this.exercice.pb_exercice.capital_libere)) {
                    messages_champs_requis.push("le capital libéré");
                }
                return this.computeOrangeTextMessageFromElements(messages_champs_requis);
            },
            computeMontantPbMinLegal() {
                const data_to_compute = {
                    "benefices": this.exercice.benefices,
                    "capital_libere": this.exercice.pb_exercice.capital_libere,
                    "masse_salariale": this.exercice.masse_salariale,
                    "valeur_ajoutee": this.exercice.pb_exercice.valeur_ajoutee,
                };
                if (this.areCorrect(Object.values(data_to_compute)) && data_to_compute.valeur_ajoutee !== 0) {
                    internalApi.pbExercice.computeMontantPbPlancherLegal(data_to_compute)
                        .then(response => {
                            this.montantPbMinLegal = response.result;
                        });
                } else {
                    this.montantPbMinLegal = undefined;
                }

            },
            /**
             * A partir des données de l'exercice remplies par l'utilisateur, récupère le
             * résultat du calcul du montant de participation aux bénéfices minimum des scops
             */
            computeMontantPbMinScop() {
                const data_to_compute = {
                    "benefices": this.exercice.benefices,
                };
                if (this.areCorrect(Object.values(data_to_compute))) {
                    internalApi.pbExercice.computeMontantPbPlancherScop(data_to_compute)
                        .then(response => {
                            this.montantPbMinScop = response.result;
                        });
                } else {
                    this.montantPbMinScop = undefined;
                }
            },
            /**
             * A partir des données de l'exercice remplies par l'utilisateur, récupère le
             * plafond des scops (version moitié du bénéfice)
             */
            computeMontantPbPlafondScopBeneficeOnly() {
                const data_to_compute = {
                    "benefices": this.exercice.benefices,
                };
                if (this.areCorrect(Object.values(data_to_compute))) {
                    internalApi.pbExercice.computeMontantPbPlafondScopBeneficeOnly(data_to_compute)
                        .then(response => {
                            this.montantPbPlafondScopBeneficeOnly = response.result;
                        });
                } else {
                    this.montantPbPlafondScopBeneficeOnly = undefined;
                }
            },
            /**
             * A partir des données de l'exercice remplies par l'utilisateur, récupère le
             * plafond des scops (version bénéfices impactés par le capital)
             */
            computeMontantPbPlafondScopBeneficeImpactCapital() {
                const data_to_compute = {
                    "benefices": this.exercice.benefices,
                    "capital_libere": this.exercice.pb_exercice.capital_libere,
                };
                if (this.areCorrect(Object.values(data_to_compute))) {
                    internalApi.pbExercice.computeMontantPbPlafondScopBeneficeImpactCapital(data_to_compute)
                        .then(response => {
                            this.montantPbPlafondScopBeneficeImpactCapital = response.result;
                        });
                } else {
                    this.montantPbPlafondScopBeneficeImpactCapital = undefined;
                }
            },
            isFilled(field) {
                return !_.isNil(field) && field !== ""
            },
            computeLabel(dependantField, text, nullText) {
                if (this.isFilled(dependantField)) {
                    return text;
                } else {
                    return nullText;
                }
            },
            /**
             * Si non définie, met à jour la date de placement de la participation selon la date de
             * fin de l'exercice (1er jour du 6ème mois)
             */
            onDateFinChange(dateFin) {
                this.exercice.date_fin = dateFin;
                if (this.isFilled(dateFin)) {
                    this.exercice.pb_exercice.date_placement_pb = moment(dateFin).add(6, "months").set({date: 1}).format("YYYY-MM-DD");
                }
            },
            /**
             * Selon si on est en création ou édition d'un exercice, enregistre le nouvel exercice
             * ou les modifications de l'exercice. Emet selon le cas un évènement create ou update
             */
            save() {
                 // If checkbox for support_deversement not checked, send null -> None server side
                if (!this.hasSupportDeversement){
                    this.exercice.pb_exercice.support_deversement_id = null;
                }
                this.exercice.company_id = this.company.id;
                this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
                if (this.creationMode) {
                    internalApi.pbExercice
                        .postAllWithToastConfirmation(this.exercice)
                        .then(response => {
                            this.$refs.loadingComponent.resetLoading();
                            this.returnToExerciceList();
                        })
                        .catch(err => {
                            this.$refs.loadingComponent.resetLoading();
                            console.error(err);
                        });
                } else {
                    const data_to_send = _.cloneDeep(this.exercice);
                    // On ne veut pas pouvoir mettre ces données à jour depuis cet écran
                    delete data_to_send.id;
                    delete data_to_send.pb_exercice.id;
                    delete data_to_send.cap_exercice;
                    delete data_to_send.inte_exercice;
                    delete data_to_send.abo_exercice;
                    internalApi.pbExercice
                        .putByIdWithToastConfirmation(
                            this.exercice.id,
                            data_to_send
                        )
                        .then(response => {
                            this.$refs.loadingComponent.resetLoading();
                            this.returnToExerciceList();
                        })
                        .catch(err => {
                            this.$refs.loadingComponent.resetLoading();
                            console.error(err);
                        });
                }
            },
            returnToExerciceList() {
                this.$router.push({
                    name: "pb-exercices"
                });
            },
            switchHasSupportDeversement() {
                if (!this.hasSupportDeversement) {
                    this.exercice.pb_exercice.support_deversement_id = null;
                }
            },
            getTooltipTextIconeCadenas(estDeverrouille) {
                const estDeverrouilleStr = estDeverrouille ? "déverrouillée" : "verrouillée";
                return `L'option d'affectation est ${estDeverrouilleStr} sur l'espace personnel. <br/> Ce paramètre peut être modifié depuis les droits d'accès.`;
            },
        }
    };
</script>
