<!-- VUETIFY2 - OK -->
<template>
    <!-- Fenêtre modale -->
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary" @click="getStateBulletinsSouscriptionIps" block>
                <v-icon left>{{ "$vuetify.icons.download" }}</v-icon>
                Bulletins de souscription
            </v-btn>
        </template>
        <v-card>
            <loading-screen ref="loadingComponent"/>
            <v-card-title class="headline grey lighten-2" primary-title>Bulletins de souscription</v-card-title>
            <v-card-text>
                <v-container fluid>
                    <div>
                        Les bulletins de souscription pour les intérêts aux parts sociales de cet exercice 
                        <span v-if="state_dernier_traitement==='never_happened'">
                            n'ont pas encore été générés.
                        </span>
                        <span v-else-if="state_dernier_traitement==='finished'">
                            ont été générés le {{ date_dernier_traitement | dateIsoToFrFilter }}.
                        </span>
                        <span v-else-if="state_dernier_traitement==='running'">
                            sont en cours de génération depuis le {{ date_dernier_traitement | dateIsoToFrFilter }}.
                        </span>
                        <span v-else>
                            sont indisponibles.
                        </span>
                    </div>
                    <v-row>
                        <v-col cols="auto">
                            <v-radio-group v-model="selectedAction">
                                <v-radio :label="labelGenerate" :value="valueGenerate" :disabled="state_dernier_traitement==='running'"/>
                                <v-radio :label="labelDownload" :value="valueDownload" :disabled="state_dernier_traitement!=='finished'"/>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center">
                        <span>
                            Les bulletins de souscription doivent dans un premier temps être générés de manière asynchrone.
                            <br/>
                            Dès que le traitement sera terminé, vous pourrez télécharger l'intégralité des documents.
                            <span v-if="state_dernier_traitement==='finished' && selectedAction===valueGenerate"
                            style="color: orange; text-align:justify">
                            <br/>
                            Le fait de re-générer les bulletins de souscription vous empêchera de les télécharger jusqu'à ce que le nouveau traitement soit terminé.
                            </span>
                        </span>
                    </v-row>
                    <v-row dense justify="end">   
                        <!-- Bouton modal -->
                        <v-col md="4" lg="4" xl="4">
                            <v-btn @click="closePopUp" block>
                                Non, annuler
                            </v-btn>
                        </v-col>
                        <!-- Bouton modal -->
                        <v-col cols="4" class="mr-4">
                            <v-btn color="primary" @click="actionBulletinsSouscriptionIps" block>
                                Oui, confirmer
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import loadingScreen from "@/components/LoadingScreen.vue";
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils";
    import constantes from "@/utils/constantes";


    /**
     * Composant affichant une pop-up pour manipuler les bulletins de souscription pour les ips d'un exercice
     * @displayName Capital - Bulletins souscriptions ips
     */
    export default {
        name: "VBulletinsSouscriptionIps",
        components: {
            loadingScreen
        },
        data() {
            return {
                dialog: false,
                state_dernier_traitement: undefined,
                date_dernier_traitement: undefined,
                selectedAction: undefined,
                labelGenerate: "Générer les bulletins de souscription",
                valueGenerate: "generate",
                labelDownload: "Télécharger les bulletins de souscription",
                valueDownload: "download",
            }
        },
        props: {
            /**
             * L'id du CapExercice concerné
             */
            capExerciceId: {
                type: Number,
                required: true
            }
        },
        methods: {
            closePopUp() {
                this.selectedAction = undefined;
                this.state_dernier_traitement = undefined;
                this.date_dernier_traitement = undefined;
                this.dialog = false;
            },
            getStateBulletinsSouscriptionIps(){
                internalApi.capExercice.affectation.getStateBulletinsSouscriptionIps(this.capExerciceId).then(result => {
                    this.state_dernier_traitement = result.state;
                    this.date_dernier_traitement = result.date_dernier_traitement;
                    if(result.state==='finished'){
                        this.selectedAction = this.valueDownload;
                    }else if(result.state==='never_happened'){
                        this.selectedAction = this.valueGenerate;
                    }
                });
            },
            actionBulletinsSouscriptionIps(){
                if (this.selectedAction===this.valueGenerate){
                    return this.generateBulletinsSouscriptionIps();
                } else if (this.selectedAction===this.valueDownload){
                    return this.downloadBulletinsSouscriptionIps();
                }
            },
            /**
             * Lance la génération des bulletins de souscription des ips
             */
            generateBulletinsSouscriptionIps(){
                this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
                internalApi.capExercice.affectation.generateBulletinsSouscriptionIps(this.capExerciceId).then(() => {
                    // Peut poser problème si on ne reset pas avant de fermer la pop-up
                    this.$refs.loadingComponent.resetLoading();
                    this.closePopUp();
                })
                .catch(err => {
                    console.log(err);
                    this.$refs.loadingComponent.resetLoading();
                });
            },
            /** Télécharge les bulletins de souscription des ips */
            downloadBulletinsSouscriptionIps(){
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                internalApi.capExercice.affectation.getBulletinsSouscriptionIps(this.capExerciceId).then(result => {
                    Utils.downloadFile(result, "bulletins_souscriptions_ips.pdf", Utils.pdfMimetype);
                    this.$refs.loadingComponent.resetLoading();
                    this.closePopUp();
                })
                .catch(err => {
                    console.log(err);
                    this.$refs.loadingComponent.resetLoading();
                });
            }
        }
    }
</script>
