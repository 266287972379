<!-- VUETIFY2 - OK -->
<template>
    <base-layout :withFooter="false" headerSize="220px">
        <template v-slot:header>
            <h1>Répartition de la participation</h1>
            <v-row no-gutters>
                <v-col class="my-4" cols="4">
                    <p>Veuillez sélectionner un exercice afin d'afficher la répartition et l'affectation de la participation aux bénéfices correspondantes :</p>
                    <ValidationProvider vid="selectedExercice" name="Exercice"
                                        rules="required"
                                        v-slot="{ errors }">
                        <v-exercices-list :value="selectedExerciceId"
                                          @update-selected-exercice="onExerciceChange($event)"
                                          :errors="errors"
                                          ref="exercices"/>
                    </ValidationProvider>
                </v-col>
            </v-row>
        </template>

        <template v-slot:main>
            <v-container>
                <loading-screen ref="loadingComponent"/>

                <h2>Répartition de la participation aux bénéfices</h2>
                <v-datatable-repartition-pb-espace-beneficiaire
                    @update-repartitions="repartitions = $event"
                    @update-repartition-uniforme-active="repartitionUniformeActive = $event"
                    @update-repartition-presence-active="repartitionPresenceActive = $event"
                    @update-repartition-salaire-active="repartitionSalaireActive = $event"
                    @update-repartition-anciennete-active="repartitionAncienneteActive = $event"
                    @update-nombre-distributions="nombreDistributions = $event"
                    @update-detail-calcul="detailCalcul = $event"
                    :repartitions="repartitions"
                    :repartitionUniformeActive="repartitionUniformeActive"
                    :repartitionPresenceActive="repartitionPresenceActive"
                    :repartitionSalaireActive="repartitionSalaireActive"
                    :repartitionAncienneteActive="repartitionAncienneteActive"
                    :nombreDistributions="nombreDistributions"
                    :detailCalcul="detailCalcul"
                    :selectedExercice="selectedExercice"
                    :selectedExerciceId="selectedExerciceId"
                    :noDataTextMessage="computeMessage(true)"
                />
                <v-row no-gutters class="mt-3">
                    <!-- Petit bouton -->
                    <v-col md="3" lg="3" xl="2" v-if="repartitions.length">
                        <detail-repartition-pb :explain="detailCalcul"/>
                    </v-col>
                    <v-spacer/>
                    <!-- Petit bouton -->
                    <v-col md="3" lg="3" xl="2">
                        <v-btn color="primary" @click="downloadFicheIndividuelle" :disabled="!canGetFicheIndividuelle || !selectedExercice.pb_exercice.is_repartition_pb_valide || repartitions.length == 0" block>
                            <v-icon left>{{ "$vuetify.icons.download" }}</v-icon>
                            Fiche individuelle
                        </v-btn>
                    </v-col>
                </v-row>

                <h2>Affectation de la participation aux bénéfices</h2>
                <v-datatable-ventilation-pb-espace-beneficiaire
                    @update-ventilations="ventilations = $event"
                    @update-pb-supports="pbSupports = $event"
                    :ventilations="ventilations"
                    :pbSupports="pbSupports"
                    :selectedExercice="selectedExercice"
                    :noDataTextMessage="computeMessage(false)"
                    :dataChanged="false"
                    :disabledValue="!canSaveAffectation"
                />
                <v-row no-gutters justify="end" class="mt-3">
                    <!-- Petit bouton -->
                    <v-col md="3" lg="3" xl="2" v-if="ventilations.length && !selectedExercice.pb_exercice.is_ventilation_pb_valide">
                        <v-btn block color="secondary" @click="saveVentilationIndividuel" :disabled="!canSaveAffectation">
                            <v-icon dark left>{{ "$vuetify.icons.save" }}</v-icon>
                            Enregistrer
                        </v-btn>
                    </v-col>
                </v-row>

            </v-container>
        </template>
    </base-layout>
</template>

<script>
    import DetailRepartitionPb from "@/components/repartitionPb/VDetailRepartitionPb";
    import VExercicesList from "@/components/VExercicesList";
    import VDatatableRepartitionPbEspaceBeneficiaire
        from "@/components/repartitionPb/VDatatableRepartitionPbEspaceBeneficiaire";
    import VDatatableVentilationPbEspaceBeneficiaire
        from "@/components/ventilationPb/VDatatableVentilationPbEspaceBeneficiaire";
    import internalApi from "@/api/internalApi";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import Utils from "@/utils";
    import constantes from "@/utils/constantes";
    import BaseLayout from "@/components/BaseLayout";

    export default {
        name: "RepartitionPbEspaceBeneficiaire",
        components: {
            VExercicesList,
            DetailRepartitionPb,
            VDatatableRepartitionPbEspaceBeneficiaire,
            VDatatableVentilationPbEspaceBeneficiaire,
            loadingScreen,
            BaseLayout
        },
        data() {
            return {
                selectedExerciceId: undefined,
                selectedExercice: undefined,
                repartitions: [],
                nombreDistributions: 1,
                detailCalcul: {},
                repartitionUniformeActive: false,
                repartitionPresenceActive: false,
                repartitionSalaireActive: false,
                repartitionAncienneteActive: false,
                canGetFicheIndividuelle: false,
                canSaveAffectation: false,
                ventilations: [],
                pbSupports: [],
            }
        },
        mounted() {
            this.canSaveAffectation = this.$store.getters.hasDroitAcces(
                constantes.droits_acces.noms.espace_participation,
                [constantes.droits_acces.valeurs_possibles.edition],
            )
        },
        methods: {
            getPbExerciceById(pb_exercice_id) {
                return internalApi.pbExercice.getById(pb_exercice_id).then(
                    result => {
                        this.selectedExercice = result;
                        this.selectedExerciceId = result.id;
                    }
                );
            },
            computeMessage(isRepartition) {
                let message = "Aucune participation aux bénéfices pour cet exercice.";
                if (this.selectedExerciceId === undefined) {
                    message = "Veuillez sélectionner un exercice.";
                } else if (!this.selectedExercice.pb_exercice.is_repartition_pb_valide) {
                    if (isRepartition) {
                        message = "Cet exercice n'ayant pas encore été validé en assemblée générale, la répartition de la participation n'est pas disponible.";
                    } else {
                        message = "Cet exercice n'ayant pas encore été validé en assemblée générale, l'affectation de la participation n'est pas disponible.";
                    }
                }
                return message;
            },
            onExerciceChange(selected_exercice) {
                this.getPbExerciceById(selected_exercice.pb_exercice_id);
                this.checkCanGetFicheIndividuelle(selected_exercice.pb_exercice_id);
            },
            checkCanGetFicheIndividuelle(pb_exercice_id) {
                internalApi.repartitionPb.checkBenefHasRepartitionOrOperationsOnSupport(pb_exercice_id)
                    .then(result => {
                        this.canGetFicheIndividuelle = result["result"];
                    })
            },
            downloadFicheIndividuelle() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                internalApi.repartitionPb.getFicheIndividuelle(this.selectedExerciceId)
                    .then(result => {
                        Utils.downloadFile(result, "fiche_individuelle_participation.pdf", Utils.pdfMimetype);
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    })
            },
            saveVentilationIndividuel() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
                internalApi.ventilationPb.saveVentilationPbIndividuel(this.selectedExerciceId, this.ventilations)
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
        }
    }
</script>