import Utils from "@/utils.js";
import {apiConstructor} from "@/api";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";

export default capAffectationApi;


function capAffectationApi() {
  return {
    getCapAffectationByExerciceId: getCapAffectationByExerciceId(),
    postSaveCapAffectationByExerciceId: postSaveCapAffectationByExerciceId(),
    postValidateCapAffectationByExerciceId: postValidateCapAffectationByExerciceId(),
    postSaveCapAffectationIndividuel: postCapAffectationIndividuel(),
    getAffectationCapitalIndividuelByExerciceId:getAffectationCapitalIndividuelByExerciceId(),
    exportCapAffectationModeleExcel: exportCapAffectationModeleExcel(),
    importCapAffectationExcel: importCapAffectationExcel(),
    getStateBulletinsSouscriptionIps: getStateBulletinsSouscriptionIps(),
    generateBulletinsSouscriptionIps: generateBulletinsSouscriptionIps(),
    getBulletinsSouscriptionIps: getBulletinsSouscriptionIps(),
  };
}


function getCapAffectationByExerciceId() {
  function getCapAffectationByExerciceId(exerciceId, page, rowsPerPage, sortBy, descending, queryParams, headers) {
    let path = internalApiRoutes.capExercices.affectation.baseUrl(exerciceId);
    queryParams = queryParams || {};
    queryParams.page = page;
    queryParams.rows_per_page = rowsPerPage;
    queryParams.sort_key = sortBy;
    queryParams.descending = descending;
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getCapAffectationByExerciceId;
}

function postSaveCapAffectationByExerciceId() {
  function postSaveCapAffectationByExerciceId(exerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.capExercices.affectation.baseUrl(exerciceId);
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return postSaveCapAffectationByExerciceId;
}

function postCapAffectationIndividuel() {
  function postSaveCapAffectationIndividuel(exerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.capExercices.affectation.affectationIndividuel(exerciceId)
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return postSaveCapAffectationIndividuel;
}

function postValidateCapAffectationByExerciceId() {
  function postValidateCapAffectationByExerciceId(exerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.capExercices.affectation.validate(exerciceId);
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return postValidateCapAffectationByExerciceId;
}

function getAffectationCapitalIndividuelByExerciceId() {
  function getAffectationIndividuel(exerciceId, queryParams, headers) {
    let path = internalApiRoutes.capExercices.affectation.affectationIndividuel(exerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getAffectationIndividuel;
}

function exportCapAffectationModeleExcel() {
  function exportCapAffectationExcel(exerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.capExercices.affectation.exportCapAffectationModeleExcel(exerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return exportCapAffectationExcel
}

function importCapAffectationExcel() {
  function importCapAffectationExcel(exerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.capExercices.affectation.importCapAffectationExcel(exerciceId);
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return importCapAffectationExcel;
}

function getStateBulletinsSouscriptionIps() {
  function getState(capExerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.capExercices.affectation.getStateBulletinsSouscriptionIps(capExerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getState;
}

function generateBulletinsSouscriptionIps() {
  function generateBulletins(capExerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.capExercices.affectation.generateBulletinsSouscriptionIps(capExerciceId);
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.asynchrone.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return generateBulletins;
}

function getBulletinsSouscriptionIps() {
  function getBulletins(capExerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.capExercices.affectation.getBulletinsSouscriptionIps(capExerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getBulletins;
}
