<template>
    <ValidationObserver v-slot="{ validate, errors }">
        <base-layout :withFooter="false" headerSize="180px" slots="data">
            <template v-slot:header>
                <h1>Abondement</h1>
                <v-row>
                    <v-col class="my-4" cols="4">
                        <ValidationProvider vid="selectedExercice" name="Exercice"
                                            rules="required"
                                            v-slot="{ errors }">
                            <v-exercices-list :value="selectedExercice"
                                            @update-selected-exercice="onExerciceChange($event.id)"
                                            :errors="errors"
                                            ref="exercices"/>
                        </ValidationProvider>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:main>
                <v-container>
                    <loading-screen ref="loadingComponent"/>
                    <p>{{ descriptionCampagne }}</p>
                    <datatable-abo-attribution-espace-associe
                        :exercice="selectedExercice"
                        :attributions="attributions"
                        :dataChanged="false"
                        @update-attributions="attributions = $event"
                        :disabledValue="!canEditAttribution"
                        :hideDetails="false"
                        ref="datatableAboAttribution"
                    />
                    <v-row no-gutters justify="end" class="mt-3">
                        <v-col md="3" lg="3" xl="2" v-if="attributions.length">
                            <v-btn block color="secondary" @click="saveAboAttributionIndividuel(validate, errors)" :disabled="!canEditAttribution">
                                <v-icon dark left >{{ "$vuetify.icons.save" }}</v-icon>
                                Enregistrer
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";
    import internalApi from "@/api/internalApi";
    import VExercicesList from "@/components/VExercicesList";
    import DatatableAboAttributionEspaceAssocie from "@/components/abondement/DatatableAboAttributionEspaceAssocie.js"
    import {TOAST} from "@/plugins/toast/definition";
    import Utils from "@/utils";
    import _ from "lodash";
    import BaseLayout from "@/components/BaseLayout";

    /**
     * Composant affichant l'abondement d'un associé pour la campagne d'un exercice donné
     */
    export default {
        components: {
            VExercicesList,
            DatatableAboAttributionEspaceAssocie,
            loadingScreen,
            BaseLayout
        },
        data() {
            return {
                attributions: [],
                selectedExercice: undefined,
                isCampagneActive: false,
                isAttributionValide: false,
            };
        },
        computed: {
            todayDate() {
                return Utils.displayDateToIsoFormat(new Date());
            },
            canEditAttribution() {
                return this.isCampagneActive && !this.isAttributionValide;
            },
            descriptionCampagne() {
                if (_.isNil(this.selectedExercice)){
                    return "Veuillez sélectionner un exercice afin d'afficher le tableau d'abondement.";
                }
                if (!this.selectedExercice.abo_exercice.is_parametrage_complete){
                    return "La campagne d'abondement n'a pas été paramétrée pour cet exercice.";
                }
                if(this.isAttributionValide){
                    return "L'abondement de cet exercice a été validé.";
                }
                var periode_msg = "la période du " + Utils.formatDateIsoToFr(this.selectedExercice.abo_exercice.date_debut_campagne) + " au " + Utils.formatDateIsoToFr(this.selectedExercice.abo_exercice.date_fin_campagne) + ".";
                if (this.isCampagneActive){
                    return "Campagne ouverte sur " + periode_msg;
                }else {
                    return "Campagne fermée en dehors de " + periode_msg;
                }
            }
        },
        methods: {
            /**
             * Via le nouvel identifiant de l'exercice, met à jour l'abo attribution de l'associé
             * @param newExId
             */
            onExerciceChange(newExId) {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                return Promise.all([this.getAboExerciceById(newExId), this.getIsCampagneActive(newExId)]).finally(()=> {
                    this.$refs.loadingComponent.resetLoading();
                });

            },
            getAboExerciceById(abo_exercice_id) {
                return internalApi.aboExercice.getById(abo_exercice_id)
                    .then(result => {
                        this.selectedExercice = result;
                        this.isAttributionValide = this.selectedExercice.abo_exercice.is_attribution_valide;
                        if (!this.selectedExercice.abo_exercice.is_parametrage_complete) {
                            this.attributions = [];
                        }
                    })
                    .catch(err => {
                        this.attributions = [];
                        console.error(err);
                    });
            },
            getIsCampagneActive(abo_exercice_id) {
                return internalApi.aboExercice.getIsCampagneActive(abo_exercice_id, {"date": this.todayDate})
                    .then(result => {
                        this.isCampagneActive = result.result;
                    });
            },
            /**
             * Display errors from the table
             */
            showErrorToast(raw_errors) {
                const errors = Object.keys(raw_errors).flatMap(key => raw_errors[key]);
                errors.forEach(error => TOAST.error("Champ requis manquant", error));
            },
            saveAboAttributionIndividuel(validate, errors) {
                validate().then(success => {
                    if (success) {
                        this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
                        const dataToSend = this.attributions[0];
                        internalApi.aboAttribution.saveAboAttributionIndividuel(this.selectedExercice.id, dataToSend)
                        .finally(() => {
                            this.$refs.loadingComponent.resetLoading();
                        });
                    } else {
                        this.showErrorToast(errors);
                    }
                })
            }
        }
    };
</script>
