<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver v-slot="{ validate, errors }">
        <base-layout :withFooter="true" headerSize="220px">
            <template v-slot:header>
                <h1>Participation aux interêts</h1>
                <v-row>
                    <v-col class="my-4" cols="4">
                        <p>Veuillez sélectionner un exercice afin d'afficher la répartition et l'affectation des intérêts aux parts sociales correspondantes :</p>
                        <ValidationProvider vid="selectedExercice" name="Exercice"
                                            rules="required"
                                            v-slot="{ errors }">
                            <v-exercices-list :value="selectedCapExercice"
                                            @update-selected-exercice="onExerciceChange($event)"
                                            :errors="errors"
                                            ref="exercices"/>
                        </ValidationProvider>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:main>
                <v-container>
                    <loading-screen ref="loadingComponent"/>

                    <h2>Répartition de la participation aux Interêts</h2>
                    <v-datatable-repartition-cap
                        :repartitionsList="repartitions"
                        :isTotal="false"
                        :noDataTextMessage="computeMessage"
                        :message_detail_extra_mouvements_after_exercice="explain.message_detail_extra_mouvements_after_exercice"
                        :isDataLoading="isDataLoading"
                    />
                    <v-row no-gutters justify="end" class="mt-3">
                        <v-col md="3" lg="3" xl="2">
                            <v-btn color="primary" @click="downloadFicheIndividuelle"
                            :disabled="selectedCapExercice === undefined || !selectedCapExercice.cap_exercice.is_repartition_valide" block>
                                <v-icon left>{{ "$vuetify.icons.download" }}</v-icon>
                                Fiche individuelle
                            </v-btn>
                        </v-col>
                    </v-row>

                    <h2>Affectation des intérêts aux parts sociales</h2>
                    <v-datatable-affectation-capital-espace-associe
                        :selectedExercice="selectedCapExercice"
                        :affectations="affectations"
                        :dataChanged="false"
                        :disabledValue="!canSaveAffectation"
                        @update-affectations="affectations = $event"
                    />
                    <v-row no-gutters justify="end" class="mt-3">
                        <v-col md="3" lg="3" xl="2" v-if="affectations.length">
                            <v-btn block color="secondary" @click="saveCapAffectationIndividuel(validate, errors)" :disabled="!canSaveAffectation">
                                <v-icon dark left >{{ "$vuetify.icons.save" }}</v-icon>
                                Enregistrer
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-container>
            </template>
            <template v-slot:footer>
                <v-row no-gutters>
                    <v-col cols="3">
                        <detail-repartition-cap
                            :explain="explain"
                            :disabledValue="selectedCapExercice === undefined || !selectedCapExercice.cap_exercice.is_repartition_valide"
                        />
                    </v-col>
                    <v-spacer/>
                    <v-col cols="3">
                        <v-btn color="primary" @click="downloadIfu2561TerAssociePDF" block
                            :disabled="selectedCapExercice === undefined || !selectedCapExercice.cap_exercice.is_repartition_valide">
                            <v-icon left>{{"$vuetify.icons.pdf"}}</v-icon>
                            Générer le CERFA 2561 ter
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
    import VDatatableRepartitionCap from "@/components/repartitionCap/VDatatableRepartitionCap";
    import VDatatableAffectationCapitalEspaceAssocie from "@/components/affectationCapital/VDatatableAffectationCapitalEspaceAssocie";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";
    import internalApi from "@/api/internalApi";
    import VExercicesList from "@/components/VExercicesList";
    import {TOAST} from "@/plugins/toast/definition";
    import Utils from "@/utils";
    import _ from "lodash";
    import DetailRepartitionCap from "@/components/repartitionCap/VDetailRepartitionCap";
    import BaseLayout from "@/components/BaseLayout";

    /**
     * Composant affichant la part d'un associé dans la répartition des intérêts
     * aux parts sociales pour un exercice donné
     */
    export default {
        components: {
            DetailRepartitionCap,
            VDatatableAffectationCapitalEspaceAssocie,
            VExercicesList,
            VDatatableRepartitionCap,
            loadingScreen,
            BaseLayout
        },
        data() {
            return {
                repartitions: [],
                affectations: [],
                explain: {message_detail_extra_mouvements_after_exercice: undefined},
                selectedCapExercice: undefined,
                isDataLoading: false,
                canSaveAffectation: false,
            };
        },
        mounted() {
            this.canSaveAffectation = this.$store.getters.hasDroitAcces(
                constantes.droits_acces.noms.espace_capital,
                [constantes.droits_acces.valeurs_possibles.edition],
            )
        },
        computed: {
            computeMessage() {
                let message = "Aucun intérêt aux parts sociales pour cet exercice.";
                if (_.isNil(this.selectedCapExercice)) {
                    message = "Veuillez sélectionner un exercice.";
                } else if (!this.selectedCapExercice.cap_exercice.is_repartition_valide) {
                    message = "Cet exercice n'ayant pas encore été validé en assemblée générale, aucun intérêt aux parts sociales n'est disponible.";
                }
                return message;
            }
        },
        methods: {
            /**
             * Récupère la part obtenue par l'associé connecté dans la répartition
             * des intérêts aux parts sociales pour l'exercice donné
             */
            computeRepartition() {
                if (!_.isNil(this.selectedCapExercice)) {
                    this.isDataLoading = true;
                    internalApi.capExercice.repartition
                    .getRepartitionCapByExerciceIdEspaceAssocie(this.selectedCapExercice.id)
                    .then(res => {
                        this.explain = res.explain;
                        this.repartitions = res.data;
                    })
                    .finally(() => {
                        this.isDataLoading = false;
                    });
                }
            },
            /**
             * Via le nouvel identifiant de l'exercice, met à jour la répartition de l'associé
             * si la répartition est validée, sinon affiche un warning indiquant que la
             * répartition n'est pas valide
             * @param newExId
             */
            onExerciceChange(newExId) {
                let promise_cap_exercice = this.getCapExerciceById(newExId.cap_exercice_id);
                Promise.all([promise_cap_exercice]).then(res => {
                    if (this.selectedCapExercice.cap_exercice.is_repartition_valide) {
                        this.computeRepartition();
                    } else {
                        this.repartitions = [];
                        this.explain = {};
                        TOAST.warning("Cet exercice n'a pas encore été validé en AG.", Utils.EMPTY_STRING);
                    }
                });
            },
            getCapExerciceById(cap_exercice_id) {
                return internalApi.capExercice.getById(cap_exercice_id).then(capEx => {
                    this.selectedCapExercice = capEx;
                });
            },
            /**
             * Télécharge le document fiscal Ifu2561ter pour l'associé utilisateur
             */
            downloadIfu2561TerAssociePDF: function () {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                internalApi.fiscalite_capital.ifu2561terAssociePDF(this.selectedCapExercice.id)
                .then(response => {
                    this.$refs.loadingComponent.resetLoading();
                    Utils.downloadFile(response, "IFU_2561_ter.pdf", Utils.pdfMimetype);
                })
                .catch(err => {
                    console.error(err);
                    this.$refs.loadingComponent.resetLoading();
                });
            },
            downloadFicheIndividuelle() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                internalApi.capExercice.repartition.getCapFicheIndividuelle(this.selectedCapExercice.id)
                .then(result => {
                    Utils.downloadFile(result, "fiche_individuelle_capital.pdf", Utils.pdfMimetype);
                })
                .finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
            },
            /**
             * Display errors from the table
             */
            showErrorToast(raw_errors) {
                const errors = Object.keys(raw_errors).flatMap(key => raw_errors[key]);
                errors.forEach(error => TOAST.error("Champ requis manquant", error));
            },
            saveCapAffectationIndividuel(validate, errors) {
                validate().then(success => {
                    if (success) {
                        this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
                        const dataToSend = {
                            "affectations": this.affectations,
                        };
                        internalApi.capExercice.affectation.postSaveCapAffectationIndividuel(this.selectedCapExercice.id, dataToSend)
                        .finally(() => {
                            this.$refs.loadingComponent.resetLoading();
                        });
                    } else {
                        this.showErrorToast(errors);
                    }
                })
            }
        }
    };
</script>
