<!-- VUETIFY2 - OK -->
<template>
    <v-container fluid class="pa-0">
        <v-row no-gutters v-if="withHeader" v-bind:style="{ height: headerSize}">
            <v-col align-self="stretch">
                <div class="pa-3">
                    <slot name="header"/>
                </div>
            </v-col>
        </v-row>
        <v-row no-gutters class="main-slot-container" ref="mainShadows">
            <div class="shadow-before"></div>
            <div class="shadow-after"></div>
            <v-col :style="{height: mainSize, overflowY: mainOverflowProp}" ref="mainScroll">
                <slot name="main" :datatableSize="datatableSize" :mainSize="mainSize"/>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="withFooter" class="px-3 pt-2" v-bind:style="{height: footerSize}">
            <v-col>
                <slot name="footer"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

    import Utils from "@/utils";

    /**
     * Basic layout for pages.
     * The header is fixed at the top just below the toolbar.
     * The footer is fixed at the bottom
     */
    export default {
        name: "BaseLayout",
        props: {
            withHeader: {
                type: Boolean,
                required: false,
                default: true
            },
            headerSize: {
                type: String,
                required: false,
                default: "100px"
            },
            withFooter: {
                type: Boolean,
                required: false,
                default: true
            },
            footerSize: {
                type: String,
                required: false,
                default: "52px"
            },
            datatableExcludedSize: {
                type: String,
                required: false,
                default: "80px"
            },
            mainOverflowProp: {
                type: String,
                required: false,
                default: "auto"
            },
        },
        mounted() {
            this.initScrollEffectsOnElement(this.$refs.mainShadows, this.$refs.mainScroll);
        },
        computed: {
            headerRealSize() {
                return this.withHeader ? this.headerSize : "0px";
            },
            footerRealSize() {
                return this.withFooter ? this.footerSize : "0px";
            },
            mainSize() {
                return `calc(100vh - 64px - ${this.headerRealSize} - ${this.footerRealSize} - ${this.getParentBaseLayoutSize()})`;
            },
            datatableSize() {
                return `calc(${this.mainSize} - ${this.datatableExcludedSize})`;
            }
        },
        methods: {
            setShadowsMargins(elementToApplyShadows) {
                // Déterminer la largeur de la scrollbar et ajouter une marge pour que les ombres ne passent pas par dessus la scrollbar
                const scrollbarWidth = Utils.getScrollbarWidth();
                let nodes = elementToApplyShadows.querySelectorAll(':scope > div.shadow-before, :scope > div.shadow-after');
                nodes.forEach(node => {
                    node.style.marginRight = scrollbarWidth + "px";
                });
            },
            initScrollEffectsOnElement(elementToApplyShadows, elementScrollContainer) {

                // Calcul initial des ombres
                this.computeScrollEffectsOnElement(elementToApplyShadows, elementScrollContainer);
                this.setShadowsMargins(elementToApplyShadows);

                // Recalculer les ombres autour du scroll du main quand nécéssaire
                elementScrollContainer.addEventListener("scroll", (event) => {
                    this.computeScrollEffectsOnElement(elementToApplyShadows, elementScrollContainer);
                });
                const RESIZE_OB = new ResizeObserver(entries => {
                    this.computeScrollEffectsOnElement(elementToApplyShadows, elementScrollContainer);
                    this.setShadowsMargins(elementToApplyShadows);
                });
                RESIZE_OB.observe(elementScrollContainer);

            },
            computeScrollEffectsOnElement(elementToApplyShadows, elementScrollContainer) {
                if (elementScrollContainer.scrollHeight == elementScrollContainer.clientHeight) {
                    // Pas de scrolling
                    elementToApplyShadows.classList.remove("shadow-border-top", "shadow-border-bottom");
                } else if (elementScrollContainer.scrollTop == 0) {
                    // En haut du scrolling
                    elementToApplyShadows.classList.remove("shadow-border-bottom");
                    elementToApplyShadows.classList.add("shadow-border-top");
                } else if (elementScrollContainer.scrollTop >= elementScrollContainer.scrollHeight - elementScrollContainer.clientHeight) {
                    // En bas du scrolling
                    elementToApplyShadows.classList.remove("shadow-border-top");
                    elementToApplyShadows.classList.add("shadow-border-bottom");
                } else {
                    // Au milieu du scrolling
                    elementToApplyShadows.classList.add("shadow-border-top", "shadow-border-bottom");
                }
            },
            getParentBaseLayoutSize() {
                let p = this.$parent;
                let cumulHeaderSize = ["0px"];
                let cumulFooterSize = ["0px"];
                while (typeof p !== 'undefined') {
                    if (p.$options.name === "BaseLayout") {
                        if(p.withHeader){
                            cumulHeaderSize.push(p.headerSize);
                        }
                        if(p.withFooter){
                            cumulFooterSize.push(p.footerSize);
                        }
                    }
                    p = p.$parent;
                }
                const sumPreviousHeader = _.reduce(cumulHeaderSize, (i, j) => {return i + " - " + j});
                const sumPreviousFooter = _.reduce(cumulFooterSize, (i, j) => {return i + " - " + j});
                return sumPreviousHeader + " - " + sumPreviousFooter;
            }
        }
    }
</script>
<style scoped>
    .main-slot-container {
        position:relative;
    }
    .main-slot-container>.shadow-before, .main-slot-container>.shadow-after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        z-index: 99;
        transition: opacity 0.2s ease-in-out;
        opacity: 0;
    }
    .main-slot-container>.shadow-before {
        box-shadow: 0px -6px 3px -3px rgba(0, 0, 0, 0.10) inset;
    }
    .main-slot-container>.shadow-after {
        box-shadow: 0px 6px 3px -3px rgba(0, 0, 0, 0.10) inset;
    }

    .shadow-border-top>.shadow-before {
        opacity: 1;
    }
    .shadow-border-bottom>.shadow-after {
        opacity: 1;
    }
</style>