<!-- VUETIFY2 - OK -->
<template>
    <v-container fluid>
        <loading-screen ref="loadingComponent"/>
        <v-row v-if="this.operationsBySupports.length > 0" no-gutters>
            <p>Montant total : {{ this.montantTotal | roundEuroFilter }}</p>
        </v-row>
        <v-row no-gutters>
            <v-tabs v-model="currentTab">
                <v-tab v-for="supp in operationsBySupports" :key="supp.name">{{supp.name}}</v-tab>
                <v-tab-item v-for="supp in this.operationsBySupports" :key="supp.name">
                    <v-row align="stretch" no-gutters>
                        <v-col align-self="stretch">
                            <v-data-table :footer-props="footerProps"
                                          :headers="headers"
                                          :items="supp.mouvements_support_beneficiaire"
                                          :options.sync="pagination"
                                          fixed-header
                                          :height="height"
                                          class="elevation-1"
                                          no-data-text="Aucun mouvement">
                                <template v-slot:item="{item}">
                                    <tr>
                                        <td class="text-lg-left">
                                            {{item.date_mouvement | dateIsoToFrFilter}}
                                        </td>
                                        <td class="text-lg-left">
                                            <v-row>
                                                <v-col shrink>
                                                    {{item.type_mouvement}}
                                                </v-col>
                                            </v-row>
                                        </td>
                                        <td class="text-lg-left">
                                            <v-row>
                                                <v-col shrink>
                                                    {{item.montant_signe || item.montant | roundEuroFilter}}
                                                </v-col>
                                            </v-row>
                                        </td>
                                        <td class="text-lg-left">
                                            {{item.date_disponibilite | dateIsoToFrFilter}}
                                        </td>
                                        <td class="text-lg-left">
                                            {{item.montant_cumule_a_date_ope | roundEuroFilter}}
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs>
        </v-row>
    </v-container>
</template>

<script>
    import loadingScreen from "@/components/LoadingScreen.vue";
    import internalApi from "@/api/internalApi";
    import constantes from "@/utils/constantes";

    export default {
        name: "VDatatableMouvementsSupports",
        components: {
            loadingScreen
        },
        props: {
            height: {
                type: String,
                required: false,
                default: undefined,
            },
            beneficiaireId: Number,
            dateOperation: String,
        },
        data() {
            return {
                pagination: {
                },
                headers: [
                    {text: "Date", sortable: true, value: "date_mouvement", align: "left"},
                    {text: "Opération", sortable: true, value: "operation", align: "left"},
                    {text: "Montant", sortable: true, value: "montant", align: "left"},
                    {text: "Date de disponibilité", sortable: true, value: "date_disponibilite", align: "left"},
                    {text: "Montant cumulé", sortable: true, value: "montant_cumule_a_date_ope", align: "left"},
                ],
                footerProps: {
                    itemsPerPageOptions: [
                        10,
                        20,
                        50,
                    ],
                },
                currentTab: 0,
                operationsBySupports: [],
                montantTotal: 0,
            }
        },
        watch: {
            dateOperation() {
                this.updateDataTable();
            },
            beneficiaireId() {
              this.updateDataTable();
            }
        },
        mounted() {
            this.updateDataTable();
        },
        methods: {
            computeMontantTotal() {
                let total = 0;
                for (let pb_support of this.operationsBySupports) {
                    for (let operation of pb_support.mouvements_support_beneficiaire) {
                        total += operation.montant_signe;
                    }
                }
                this.montantTotal = total;
            },
            updateDataTable() {
              if (!_.isNil(this.beneficiaireId)) {
                this.getOperationsList();
              }
            },
            getOperationsList() {
              this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
              return internalApi.operations.getOperationsBySupports(this.beneficiaireId, this.dateOperation)
                .then(result => {
                  this.operationsBySupports = result;
                  this.computeMontantTotal();
                  return Promise.resolve(result);
                })
                .catch(err => {
                  console.log(err);
                })
                .finally(() => {
                  this.$refs.loadingComponent.resetLoading();
                });
            },
        }
    }
</script>